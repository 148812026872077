// @ts-nocheck
import React, {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import maisSvg from '../../assets/png/mais.png';

import DrIconFont from '../dr-icon-font';
import { Container, Error, FieldIcon, ImgDrSpinner, Label } from './styled';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  options?: any[];
  selectTitle?: string | undefined | null;
  initialValue?: string | number | null;
  onChange?: (ev: any) => any;
  icon?: string;
  iconColor?: string;
  iconSize?: string;
  mandatory?: boolean;
  loading?: boolean;
}
const DrSelect: React.FC<SelectProps> = ({
  name,
  options = [{ label: 'Selecione', value: '0' }],
  selectTitle,
  onChange,
  initialValue,
  loading = false,
  mandatory = false,
  icon,
  iconColor = '#fcaf17',
  iconSize = '28',
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const [isFocused, setIsFocused] = useState(false);

  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const selectionRef = useRef(
    options.findIndex(
      (item: any) => item.value === (defaultValue || initialValue)
    ) || 0
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.options) {
          return [];
        }
        return ref.options[selectionRef.current > 0 ? selectionRef.current : 0]
          .value;
      },
      setValue: (ref, value) => {
        selectionRef.current = ref.options.selectedIndext;
      },
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    clearError();
  }, [options]);

  function handleOnChange(ev) {
    clearError();
    const { selectedIndex, value } = ev.currentTarget;

    if (onChange) onChange(ev);
    selectionRef.current = selectedIndex;
  }

  const handleInputFocus = useCallback(() => {
    setIsFocused(() => true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused((prev) => !prev);
  }, []);

  return (
    <Container
      isDisabled={!!rest.disabled || false}
      isError={!!error}
      hasIcon={!!icon}
      loading={!!loading}
    >
      <div className="field-select">
        {icon && (
          <FieldIcon color={iconColor} size={iconSize}>
            <DrIconFont name={icon} />
          </FieldIcon>
        )}
        <select
          className="form-control"
          ref={selectRef}
          name={name}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onChange={handleOnChange}
          disabled={rest.disabled}
          defaultValue={defaultValue}
          data-cy={name}
        >
          {options.map((option, i) => (
            <option
              key={option.value}
              label={option.label}
              value={option.value}
              selected={selectionRef.current === i}
            >
              {option.label}
            </option>
          ))}
        </select>
        {error && (
          <FieldIcon color={iconColor} size={iconSize}>
            <Error
              visible={isFocused}
              click={() => selectRef.current?.focus()}
              title={error}
            >
              <DrIconFont name="alert" color="#c00" />
            </Error>
          </FieldIcon>
        )}
        {loading && <ImgDrSpinner src={maisSvg} width={25} height={25} />}
        <Label>
          {selectTitle && (
            <h4>
              {mandatory && <span style={{ color: '#c00' }}> *</span>}
              {selectTitle}
            </h4>
          )}
        </Label>
      </div>

      {/* <FieldIcon>
          <DrIconFont name="caret-down" />
        </FieldIcon> */}
    </Container>
  );
};
export default DrSelect;
