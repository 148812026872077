// @ts-nocheck
import React, { ChangeEvent, useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDropzone } from 'react-dropzone';
import { BiPhotoAlbum } from 'react-icons/bi';
import { FaSave } from 'react-icons/fa';
import { FiX } from 'react-icons/fi';
import PerfilIcon from '../../assets/backgrounds/foto-perfil.png';
import { ReactComponent as Camera } from '../../assets/svg/camera.svg';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { BoxPicture, BoxPictureUpload } from '../../styles/box-picture_upload';
import { convertUrlToFile, valueParam } from '../../utils/bibli';
import {
  ButtonChangeAvatar,
  ButtonSaveAvatar,
  Container,
  I,
  PopUpContainer,
  PopUpContent,
  PutPhoto,
  ZoomInput,
} from './styled';

interface Props {
  onFileUploaded: (file: File) => void;
  url_img?: string | null;
  border_color?: string;
  hideOtherElements: () => void;
  getImage: (file: File | undefined | null) => void;
}

interface IdUser {
  id: string;
}

function onErrorImgAvatar(event: React.SyntheticEvent<HTMLImageElement>) {
  const eventChanged = event;
  eventChanged.currentTarget.src = PerfilIcon;
}
const DropZone: React.FC<Props> = ({
  url_img,
  getImage,
  hideOtherElements,
}) => {
  const { applicationData, sendError } = useAuth();
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>();
  const [fileUrl, setFileUrl] = useState<string | null>();
  const profileRef = useRef<HTMLImageElement>(null);
  const [avatar, setAvatar] = useState<AvatarEditor>();
  const [avatarRemoved, setAvatarRemoved] = useState(false);

  const [isClosed, setIsClosed] = useState(false);
  const [hideContainer, setHideContainer] = useState(true);
  const [scaleX, setScaleX] = useState(1);
  const [isAvatarEdited, setIsAvatarEdited] = useState(false);
  const { addToast } = useToast();

  function openPupUp() {
    hideOtherElements();
    setIsClosed(true);
    setHideContainer(false);
  }

  function closePupUp() {
    hideOtherElements();
    setIsClosed(false);
    setHideContainer(true);
  }

  function cancelChanges() {
    setSelectedFileUrl(
      url_img || selectedFileUrl || valueParam(applicationData, 126)
    );
    setFileUrl(null);
    hideOtherElements();
    setAvatarRemoved(false);
    setIsClosed(false);
    setHideContainer(true);
    setIsAvatarEdited(false);
    setScaleX(1);
  }

  function onDrop(acceptedFiles) {
    try {
      const image = acceptedFiles[0];
      const url = URL.createObjectURL(image);
      setFileUrl(url);

      setIsAvatarEdited(true);
      setAvatarRemoved(false);
      if (!isClosed) {
        openPupUp();
      }
    } catch (e: any) {
      sendError(e);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: 'Imagem não suportada',
      });
    }
  }
  const saveImage = (e: any) => {
    e.preventDefault();
    if (avatar && !avatarRemoved) {
      const url = avatar.getImageScaledToCanvas().toDataURL();
      const filee = convertUrlToFile(url, 'newFile.png');

      setSelectedFileUrl(url);
      getImage(filee);
    } else {
      getImage(null);
      setSelectedFileUrl(null);
    }
    setFileUrl(null);
    closePupUp();
    setAvatarRemoved(false);
    setIsClosed(false);
    setHideContainer(true);
    setIsAvatarEdited(false);
    setScaleX(1);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/png,image/jpg,image/jpeg',
  });

  const removePhoto = () => {
    setAvatarRemoved(true);
    setSelectedFileUrl(valueParam(applicationData, 126));
    setIsAvatarEdited(true);
    setScaleX(1);
  };

  function handleScale(e: ChangeEvent<HTMLInputElement>) {
    const scale = parseFloat(e.target.value);
    setScaleX(scale);
    setIsAvatarEdited(true);
  }

  function refOfEditor(editor: AvatarEditor) {
    if (editor) {
      setAvatar(editor);
    }
  }

  return (
    <>
      <PopUpContainer isClosed={!isClosed}>
        <PopUpContent>
          <div className="svg">
            <FiX onClick={cancelChanges} />
          </div>
          <PutPhoto>
            <AvatarEditor
              className="avatarEditor"
              ref={refOfEditor}
              width={480}
              height={480}
              borderRadius={100}
              scale={scaleX}
              color={[63, 86, 165, 0.8]}
              image={
                fileUrl ||
                selectedFileUrl ||
                url_img ||
                valueParam(applicationData, 126)
              }
            />
            <ZoomInput hideInput={!isClosed}>
              <div style={{ display: 'flex' }}>
                <span style={{ display: avatarRemoved ? 'none' : '' }}>
                  Zoom:
                </span>
                <input
                  style={{ display: avatarRemoved ? 'none' : '' }}
                  name="scale"
                  type="range"
                  onChange={handleScale}
                  value={scaleX}
                  min={1}
                  max="2"
                  step="0.01"
                  defaultValue="1"
                  className="scale"
                />
              </div>
              <div className="buttonsActions">
                <ButtonChangeAvatar
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...getRootProps()}
                  title="Abrir galeria de fotos"
                >
                  <div className="galeria">
                    <BiPhotoAlbum size={20} />
                  </div>
                  <p>Galeria</p>
                  <input
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getInputProps()}
                    id="input"
                    accept="image/*"
                    title="Abrir galeria de fotos"
                  />
                </ButtonChangeAvatar>
                <ButtonSaveAvatar
                  avatarEdited={!isAvatarEdited}
                  onClick={saveImage}
                  title="Salvar foto"
                >
                  <div className="salvar">
                    <FaSave size={20} title="Salvar foto" />
                  </div>
                  <p>Salvar</p>
                </ButtonSaveAvatar>
                {/* <ButtonRemoveAvatar
                  avatarRemoved={avatarRemoved}
                  type="button"
                  onClick={removePhoto}
                  title="Excluir foto"
                >
                  <div className="excluir">
                    <FiTrash2 size={20} title="Excluir foto" />
                  </div>
                  <p>Excluir</p>
                </ButtonRemoveAvatar> */}
              </div>
            </ZoomInput>
          </PutPhoto>
          <div className="edit-buttons" />
        </PopUpContent>
      </PopUpContainer>

      <Container
        hideContainer={!hideContainer}
        onClick={() => {
          setIsClosed((prev) => !prev);
          setHideContainer((prev) => !prev);
        }}
      >
        {applicationData && applicationData?.valores_parametros && (
          <>
            <div>
              <img
                ref={profileRef}
                src={
                  selectedFileUrl || url_img || valueParam(applicationData, 126)
                }
                alt="foto de perfil"
                onError={onErrorImgAvatar}
              />
            </div>
            <BoxPicture>
              {profileRef.current?.src === valueParam(applicationData, 126) ? (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <div {...getRootProps()}>
                  <BoxPictureUpload>
                    <I>
                      <Camera fill="#fff" height={30} width={30} />
                    </I>
                  </BoxPictureUpload>
                </div>
              ) : (
                <BoxPictureUpload>
                  <I>
                    <Camera fill="#fff" height={30} width={30} />
                  </I>
                </BoxPictureUpload>
              )}
            </BoxPicture>
          </>
        )}
      </Container>
    </>
  );
};

export default DropZone;
