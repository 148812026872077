// @ts-nocheck
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import StoreGoogle from '../../assets/img/logo-store-google.png';
import logo from '../../assets/marca/logo.png';
import LogoApple from '../../assets/svg/logo-store-apple.svg';
import { useAuth } from '../../hooks/auth';
import { AnchorStyled } from '../../styles/link-styled';
import {
  FootBannerApp,
  FootCopy,
  FootLogo,
  SiteFooter,
} from '../../styles/site-footer';
import { valueParam } from '../../utils/bibli';
import { cnpjMask } from '../dr-input/masks';

const Footer: React.FC = () => {
  const { applicationData } = useAuth();
  const { ds_copyright } = applicationData;
  return (
    <>
      {Object.keys(applicationData).length === 0 ? (
        <></>
      ) : (
        <SiteFooter>
          <Container>
            <Row>
              <FootBannerApp>
                <strong>Baixe nosso App</strong>
                <div>
                  <Link
                    to={{
                      pathname:
                        applicationData.valores_variaveis.url_loja_google,
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={StoreGoogle} alt="Google Logo" width="165px" />
                  </Link>
                  <Link
                    to={{
                      pathname:
                        applicationData.valores_variaveis.url_loja_apple,
                    }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={LogoApple}
                      alt="Apple Logo"
                      style={{
                        width: 150,
                        marginLeft: 10,
                      }}
                    />
                  </Link>
                </div>
              </FootBannerApp>
              <FootLogo>
                <div>
                  <img
                    onError={(el) => {
                      el.currentTarget.src = logo;
                    }}
                    src={
                      applicationData ? applicationData.ds_icon_app : undefined
                    }
                    width="40"
                    height="40"
                    alt="logo dr+"
                  />
                </div>
                <p>
                  Versão: {process.env.REACT_APP_VERSION} (
                  {process.env.REACT_APP_BUILD})
                </p>
                <AnchorStyled href="https://drmais.com.br" target="_blank">
                  https://drmais.com.br
                </AnchorStyled>
              </FootLogo>
              <FootCopy className="col-xl-12 col-md-12">
                Leia nossos&nbsp;
                <AnchorStyled
                  href={valueParam(applicationData, 173)}
                  target="_blank"
                >
                  termos de privacidade&nbsp;
                </AnchorStyled>
                e&nbsp;
                <AnchorStyled
                  href={valueParam(applicationData, 174)}
                  target="_blank"
                >
                  {`termos de uso dos seus dados. `}&nbsp;
                </AnchorStyled>
                <p style={{}}>
                  {`${ds_copyright} ${
                    applicationData.estabelecimento.ds_razao_social
                  }  - CNPJ: ${cnpjMask(applicationData.estabelecimento.cnpj)}`}
                </p>
              </FootCopy>
            </Row>
          </Container>
        </SiteFooter>
      )}
    </>
  );
};

export default Footer;
