// @ts-nocheck
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import DrButtonLoading from '../../../components/dr-button-loading';
import HelmetComponent from '../../../components/dr-helmet-component';
import Input from '../../../components/dr-input';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { BoxLoginBody } from '../../../styles/box-login_body';
import { Field } from '../../../styles/field';
import { Fields } from '../../../styles/fields';
import { scrollPositionTop, verifyErrorYup } from '../../../utils/bibli';
import { isValidCPF } from '../../../utils/validations';

interface ForgotPasswordFormData {
  nr_cpf: string;
  ds_email: string;
}

const DrBoxForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { requestAPI, sendError } = useAuth();
  const { addToast } = useToast();
  const [isLoading, setLoading] = useState(false);
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');

  const location = useLocation<any>();

  const history = useHistory();

  const handleSubmit = useCallback(async (data: ForgotPasswordFormData) => {
    try {
      formRef.current?.setErrors({});

      const schema = Yup.object().shape({
        nr_cpf: Yup.string()
          .test({
            message: 'CPF inválido',
            test: (value) => isValidCPF(value),
          })
          .required('CPF é obrigatório')
          .trim(),
        ds_email: Yup.string()
          .email('Digite um e-mail válido')
          .required('E-mail obrigatório')
          .trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);
      await requestAPI({
        method: 'GET',
        url: `tmUsuarioToken/T/${data.nr_cpf}/${data.ds_email}`,
        isPublic: true,
      });
      setLoading(false);

      history.push(`confirmation-code/${data.nr_cpf}/${data.ds_email}`);
    } catch (err: any) {
      sendError(err);
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  }, []);
  useEffect(() => {
    scrollPositionTop();
  }, []);
  function handleCpf(value: string) {
    setCpf(value);
  }

  function handleEmail(value: string) {
    setEmail(value);
  }

  return (
    <>
      <HelmetComponent title="Recuperar a Senha" />
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Fields>
          <Container>
            <Row>
              <Col xl={12}>
                <Field>
                  <Input
                    icon
                    name="nr_cpf"
                    label="CPF"
                    customType="cpf"
                    inputMode="numeric"
                    validate
                    defaultValue={location.state?.nr_cpf || ''}
                  />
                </Field>
              </Col>
              <Col xl={12}>
                <Field>
                  <Input
                    icon
                    name="ds_email"
                    label="E-mail"
                    customType="email"
                    validate
                    defaultValue={location.state?.ds_email || ''}
                  />
                </Field>
              </Col>
            </Row>
            <DrButtonLoading
              loading={isLoading}
              type="submit"
              title="Recuperar Senha"
              color="pink"
            />
          </Container>
        </Fields>
      </Form>
      <BoxLoginBody
        className="text-center mt-4"
        color="#fcaf17"
        colorHover="#ed145b"
      >
        <Link to="sign-in">Quero efetuar o login</Link>
      </BoxLoginBody>
    </>
  );
};

export default DrBoxForgotPassword;
