// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { SpanLabel } from '../../pages/AttendanceEnd/screen-attendance-end/styled';
import { PageSubtitle } from '../../styles/page-title';
import { upperCaseLetter, valueParam } from '../../utils/bibli';

import { personAge } from '../../utils/formatDates';
import DrButtonsFooter from '../dr-buttons-footer';

interface CardScheduleProps {
  headerData: any;
}
const DrCardHeaderSchedule: React.FC<CardScheduleProps> = (
  Props: CardScheduleProps
) => {
  const { headerData } = Props;
  const { applicationData } = useAuth();
  const history = useHistory();
  const location = useLocation();
  moment.locale('pt-br');

  headerData.dia = upperCaseLetter(
    moment(headerData.dt_agenda).local().format('dddd'),
    '-',
    '-'
  );

  const dtAgenda = React.useRef(
    `${moment(headerData.dt_agenda)
      .local()
      .format('DD/MM/YYYY HH:mm')}h (${upperCaseLetter(
      moment(headerData.dt_agenda).local().format('dddd'),
      '-',
      '-'
    )})`
  );

  const renderRef = React.useRef([
    {
      lbl: 'Médico(a): ',
      val: headerData.nm_pessoa_medico,
    },
    {
      lbl: 'CRM: ',
      val: headerData.nr_conselho,
    },
    {
      lbl: 'Especialidade: ',
      val: headerData.ds_especialidade,
    },
    {
      lbl: 'Paciente: ',
      val: headerData.nm_paciente
        ? headerData.nm_paciente
        : headerData.nm_pessoa_paciente,
    },
    ...(headerData.dt_nascimento
      ? [
          {
            lbl: 'Idade: ',
            val: `${personAge(headerData.dt_nascimento)} ${
              personAge(headerData.dt_nascimento) > 1 ? `Anos` : 'Ano'
            }`,
          },
        ]
      : []),
    {
      lbl: 'Data: ',
      val: dtAgenda.current,
    },
  ]);

  return (
    <Row className="justify-content-center">
      <Col xl={12}>
        <Row className="justify-content-center m-auto">
          <Col xl={6}>
            {renderRef.current &&
              renderRef.current.length > 0 &&
              renderRef.current.slice(0, 3).map((val, ind) => (
                <PageSubtitle>
                  <SpanLabel>{val.lbl || ''}</SpanLabel>

                  {val.val || ''}
                </PageSubtitle>
              ))}
          </Col>
          <Col xl={6}>
            {renderRef.current &&
              renderRef.current.length > 0 &&
              renderRef.current.slice(3).map((val, ind) => (
                <PageSubtitle>
                  <SpanLabel>{val.lbl || ''}</SpanLabel>

                  {val.val || ''}
                </PageSubtitle>
              ))}
            {valueParam(applicationData, 212) &&
              valueParam(applicationData, 212) !== -1 &&
              window.location.pathname === '/confirm-schedule' && (
                <h6 style={{ textAlign: 'start', color: '#ed145b' }}>
                  Evite atraso! Não será possível realizar a consulta após{' '}
                  {valueParam(applicationData, 212)}min do horário agendado.
                </h6>
              )}
          </Col>
          {headerData.avisos && headerData.avisos.length > 0 && (
            <Col
              style={{
                marginTop: 20,
                color: '#3f56a5',
                borderTop: '2px solid #7777771a',
                borderBottom: '2px solid #7777771a',
                fontSize: 15,
                fontWeight: 'bold',
                paddingBottom: 20,
              }}
            >
              <PageSubtitle
                style={{
                  marginTop: 20,
                  textAlign: 'center',
                  color: '#3f56a5',
                  fontSize: 15,
                  fontWeight: 'bold',
                  // marginBottom: 20,
                }}
              >
                AVISO IMPORTANTE
                <Row />
              </PageSubtitle>
              {headerData.avisos.map((aviso) => (
                <div>
                  <h6
                    style={{
                      textAlign: 'start',
                      color: '#ed145b',
                      display: 'inline',
                      fontWeight: 'bold',
                    }}
                  >
                    {aviso.ds_topico_aviso}
                  </h6>
                  <h6
                    style={{
                      display: 'inline',
                      textAlign: 'start',
                      color: 'blue',
                      fontSize: 14,
                    }}
                  >
                    {aviso.ds_aviso}
                  </h6>
                </div>
              ))}
            </Col>
          )}
          {(headerData.idconta_receber_cartao_movto ||
            headerData.ie_retorno === 'S' ||
            headerData.ds_produto) &&
            window.location.pathname === '/confirm-schedule' &&
            valueParam(applicationData, 241) &&
            valueParam(applicationData, 241) === 'S' && (
              <Row
                className="align-items-center justify-content-sm-between "
                style={{ marginTop: 50, paddingLeft: 10 }}
              >
                <Col xl={6}>
                  <PageSubtitle>
                    <SpanLabel>
                      Se desejar você pode anexar arquivos para enviar ao
                      médico, como: Laudos, Resultados de Exames, Cartão de
                      Vacina, Fotos de Lesões, entre outros.
                    </SpanLabel>
                  </PageSubtitle>
                </Col>
                <Col xl={6}>
                  <DrButtonsFooter
                    buttonLeft={{
                      onClick: () =>
                        history.push(`/upload-page`, location.pathname),
                      title: 'Anexar Arquivos',
                    }}
                    buttonRight={false}
                    sizeCol={8}
                  />
                </Col>
              </Row>
            )}
        </Row>
      </Col>
    </Row>
  );
};
export default DrCardHeaderSchedule;
