// @ts-nocheck
import cardValidator from 'card-validator';
import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  FiAlertCircle,
  FiClock,
  FiCreditCard,
  FiEye,
  FiEyeOff,
  FiLock,
} from 'react-icons/fi';
import InputMask from 'react-input-mask';

import { useField } from '@unform/core';
import PasswordStrengthBar from 'react-password-strength-bar';

// import moment from 'moment';
import { FieldIcon } from '../../styles/field-icon';
import { formatDateToString, isValidTime } from '../../utils/formatDates';
import DrIcon from '../dr-icon-font';

import isValidEmail, {
  isCreditCardDate,
  isValidCPF,
  isValidDateToRegister,
  isValidName,
  isValidPassword,
  isValidPhone,
} from '../../utils/validations';

import {
  cardNumber,
  currency,
  cvc,
  emailInput,
  nameInput,
  onlyNumbers,
  // phoneNumber,
  phoneNumberMask,
  timeText,
} from './masks';

import { Container, Error, Input, Label } from './styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  validate?: boolean;
  clear?: boolean;
  onValueChange?: React.Dispatch<React.SetStateAction<boolean>>;
  mask?:
    | 'onlyNumbers'
    | 'nameInput'
    | 'userLogin'
    | 'email'
    | 'login'
    | 'phoneNumber'
    | 'cep'
    | 'currency'
    | 'date'
    | 'cardNumber'
    | 'cardDate'
    | 'cvc'
    | 'cpf'
    | 'namePessoa'
    | 'timeText';
  customType?:
    | 'onlyNumbers'
    | 'orgaoEmissor'
    | 'nameInput'
    | 'name'
    | 'userLogin'
    | 'phoneNumber'
    | 'email'
    | 'login'
    | 'cep'
    | 'currency'
    | 'date'
    | 'dateText'
    | 'cardNumber'
    | 'cardDate'
    | 'cvc'
    | 'cpf'
    | 'password'
    | 'namePessoa'
    | 'timeText'
    | 'token'
    | 'numero'
    | 'logradouro'
    | 'complemento'
    | 'especialidade';
  value?: string;
  cantPaste?: boolean;
  icon?: boolean;
  label?: string | undefined | null;
  mandatory?: boolean;
  showDisplayPass?: boolean;
  getValue?: (value: any) => void;
  getError?: (value: boolean) => void;
  onFocusInput?: () => void;
  handleEnterKey?: boolean;
}

const DrInput: React.FC<InputProps> = ({
  mask,
  validate = false,
  cantPaste = false,
  mandatory = false,
  clear,
  customType,
  name,
  icon = false,
  value,
  label,
  showDisplayPass,
  getValue,
  getError,
  onFocusInput,
  handleEnterKey = true,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const placeholderRef = useRef<string>('');
  const maxLenghtCustomtypeRef = useRef<number | undefined>(undefined);
  const [iconCustomType, setIconCustomType] = useState<
    React.ReactNode | undefined
  >(undefined);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, error, registerField, clearError } = useField(name);
  const [isError, setIsError] = useState<string>();
  const [errored, setErrored] = useState(error !== '');
  const [text, setText] = useState<string>(value || '');
  const [isChanged, setIsChanged] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(true);
  // const isVisiblePasswordRef = useRef<boolean>(isVisiblePassword);

  function handleInputChangeText(event: ChangeEvent<HTMLInputElement>) {
    let inputValue = event.target.value;
    const inputValueMask = inputValue.replace(/[^\d]+/g, '');
    setErrored(error !== '');
    if (
      customType === 'login' ||
      customType === 'orgaoEmissor' ||
      customType === 'logradouro' ||
      customType === 'name' ||
      (customType === 'cep' && inputValueMask.length > 0) ||
      (customType === 'dateText' && inputValueMask.length > 0)
    ) {
      clearError();
    }

    if (validate) {
      setErrored(inputValue === '');
      setIsError('');

      if (
        customType === 'cpf' ||
        customType === 'cardDate' ||
        customType === 'dateText' ||
        customType === 'date' ||
        customType === 'phoneNumber'
      ) {
        if (customType === 'cpf') {
          inputValue = inputValue.trim();
          if (!isValidCPF(inputValue)) {
            setIsError('CPF inválido!');
            setErrored(true);
            clearError();
          }
        }

        // Arrumar
        if (customType === 'dateText' || customType === 'date') {
          if (
            !inputValue.includes('_') &&
            inputValue.length > 9 &&
            !isValidTime(inputValue, 'DD/MM/YYYY')
          ) {
            setIsError('Data inválida!');
            setErrored(true);
          } else if (
            !inputValue.includes('_') &&
            inputValue.length > 9 &&
            isValidDateToRegister(formatDateToString(inputValue, 'DD/MM/YYYY'))
          ) {
            clearError();
          }
        }

        if (customType === 'cardDate') {
          if (!inputValue.includes('_') && !isCreditCardDate(inputValue)) {
            setIsError('Data inválida');
            setErrored(true);
          } else if (
            !inputValue.includes('_') &&
            isCreditCardDate(inputValue)
          ) {
            clearError();
          }
        }

        if (customType === 'phoneNumber') {
          if (!isValidPhone(phoneNumberMask(inputValue) || '')) {
            setIsError('Número inválido');
            setErrored(true);
            clearError();
          }
        }
      } else {
        clearError();

        if (customType === 'cardNumber') {
          if (!cardValidator.number(inputValue)) {
            setIsError('Cartão inválido');
            setErrored(true);
          }
        }

        if (customType === 'email') {
          if (!isValidEmail(inputValue)) {
            setIsError('E-mail inválido!');
            setErrored(true);
            clearError();
          }
        }

        if (customType === 'password') {
          if (!isValidPassword(inputValue)) {
            setIsError(
              'Senha deve conter no mínimo 6 caracteres contendo letras minúsculas, maiúsculas e números'
            );
            setErrored(true);
          }
        }

        if (customType === 'namePessoa') {
          if (!isValidName(inputValue)) {
            setIsError('Informe nome completo');
            setErrored(true);
          }
        }

        if (customType === 'timeText') {
          if (!isValidTime(inputValue, 'HH:mm')) {
            setIsError('Informe uma hora válida');
            setErrored(true);
          }
        }
      }
      if (error !== '') setErrored(true);
    }

    setText(inputValue);
    setIsChanged(true);
  }

  const scoreWords = ['Fraco', 'Fraco', 'Média', 'Bom', 'Forte'];
  const barColors = ['#ddd', '#ff6900', '#f3d331', '#2b90ef', '#00ff6b'];

  const handleEnter = useCallback((e: any) => {
    if (e.keyCode === 13 && handleEnterKey) {
      e.preventDefault();
    }
  }, []);

  function handlePaste(e: any) {
    if (cantPaste) e.preventDefault();
  }

  const handleKeyUp = useCallback(
    (e: any) => {
      if (customType === 'onlyNumbers') {
        onlyNumbers(e);
      }
      if (customType === 'nameInput') {
        nameInput(e);
      }
      if (customType === 'namePessoa') {
        nameInput(e);
      }

      if (customType === 'email') {
        emailInput(e);
      }

      if (customType === 'currency') {
        currency(e);
      }

      if (customType === 'timeText') {
        timeText(e);
      }

      if (customType === 'cardNumber') {
        cardNumber(e);
      }

      if (customType === 'cvc') {
        cvc(e);
      }
    },

    [customType, mask]
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused((prev) => true);

    if (onFocusInput) onFocusInput();
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused((prev) => !prev);

    setIsFilled(!!inputRef.current?.value);
  }, []);
  useEffect(() => {
    setText(value || '');
  }, [value]);
  useEffect(() => {
    if (typeof getValue === 'function' && !!isChanged) {
      getValue(text);
    }

    if (typeof getError === 'function') {
      getError(errored);
    }
  }, [errored, text]);

  React.useEffect(() => {
    if (clear && text.length) {
      clearError();
      setText('');
      setIsError('');
      setErrored(false);
    } else {
      setErrored(true);
    }
    clearError();
  }, [clear]);

  React.useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current, // acessar o input como referência
      path: 'value', // acessar o valor que o input recebe
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (rest.defaultValue && (rest.defaultValue !== undefined || null)) {
      setText(rest.defaultValue.toString());
    }
  }, []);

  useEffect(() => {
    setCustomTypeData();
  }, [customType]);

  const setCustomTypeData = React.useCallback(() => {
    switch (customType) {
      case 'onlyNumbers':
        maxLenghtCustomtypeRef.current = 20;
        setIconCustomType(<></>);
        break;
      case 'orgaoEmissor':
        maxLenghtCustomtypeRef.current = 10;
        break;
      case 'login':
        placeholderRef.current = 'E-mail, nº do celular com DDD ou CPF';
        setIconCustomType(
          <DrIcon name="avatar" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'userLogin':
        placeholderRef.current = 'E-mail ou DDD+telefone';
        break;
      case 'email':
        placeholderRef.current = 'seuemail@dominio.com.br';
        setIconCustomType(
          <DrIcon name="envelope" color="#fcaf17" fontSize="28px" />
        );
        maxLenghtCustomtypeRef.current = 80;
        break;
      case 'nameInput':
        placeholderRef.current = 'Seu nome';
        setIconCustomType(
          <DrIcon name="connection" color="#fcaf17" fontSize="28px" />
        );
        maxLenghtCustomtypeRef.current = 150;
        break;
      case 'phoneNumber':
        Maskss('phoneNumber');
        placeholderRef.current = '(00) 00000-0000';
        setIconCustomType(
          <DrIcon name="call" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'date':
        Maskss('dateText');
        placeholderRef.current = 'DD/MM/AAAA';
        setIconCustomType(
          <DrIcon name="calendar" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'dateText':
        Maskss('dateText');

        placeholderRef.current = '00/00/0000';
        setIconCustomType(
          <DrIcon name="calendar" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'name':
        placeholderRef.current = 'Informe';
        setIconCustomType(
          <DrIcon name="avatar" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'namePessoa':
        placeholderRef.current = 'Seu nome completo';
        setIconCustomType(
          <DrIcon name="avatar" color="#fcaf17" fontSize="28px" />
        );
        maxLenghtCustomtypeRef.current = 150;
        break;
      case 'cpf':
        Maskss('cpf');
        placeholderRef.current = '000.000.000-00';
        setIconCustomType(
          <DrIcon name="identity-card" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'password':
        setIsVisiblePassword(false);
        placeholderRef.current = '**********';
        setIconCustomType(
          <DrIcon name="padlock" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'cardNumber':
        placeholderRef.current = '0000 0000 0000 0000';
        setIconCustomType(<FiCreditCard size={20} fill="#fcaf17" />);
        break;
      case 'cardDate':
        Maskss('cardDate');
        placeholderRef.current = 'MM/AA';
        setIconCustomType(<FiCreditCard size={20} fill="#fcaf17" />);
        break;
      case 'cvc':
        maxLenghtCustomtypeRef.current = 4;
        placeholderRef.current = 'CVV';
        setIconCustomType(<FiLock size={20} fill="#fcaf17" />);
        break;
      case 'currency':
        placeholderRef.current = 'R$ 0,00';
        break;
      case 'cep':
        Maskss('cep');
        placeholderRef.current = '00000-000';
        break;
      case 'timeText':
        setIconCustomType(<FiClock size={20} fill="#fcaf17" />);
        break;
      case 'token':
        setIconCustomType(
          <DrIcon name="padlock" color="#fcaf17" fontSize="28px" />
        );
        break;
      case 'numero':
        maxLenghtCustomtypeRef.current = 6;
        break;
      case 'logradouro':
        maxLenghtCustomtypeRef.current = 150;
        break;
      case 'complemento':
        maxLenghtCustomtypeRef.current = 100;
        break;
      case 'especialidade':
        setIconCustomType(<></>);
        break;
      default:
        return false;
    }
    return null;
  }, [customType, text]);
  function Maskss(str) {
    switch (str) {
      case 'phoneNumber':
        return '(99) 99999-9999';
      case 'dateText':
        return '99/99/9999';
      case 'cpf':
        return '999.999.999-99';
      case 'cep':
        return '99999-999';
      case 'cardDate':
        return '99/99';
      default:
        return '';
    }
  }
  return (
    <>
      <Container
        isErrored={!!error && errored}
        isFilled={isFilled}
        isFocused={isFocused}
        isDisabled={rest.disabled || false}
      >
        <FieldIcon>{icon && iconCustomType}</FieldIcon>
        <InputMask
          mask={Maskss(customType)}
          value={text}
          onChange={handleInputChangeText}
          disabled={rest.disabled || false}
          maxLength={maxLenghtCustomtypeRef.current}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        >
          <Input
            onKeyUp={handleKeyUp}
            onKeyDown={handleEnter}
            onPaste={handlePaste}
            placeholder={rest.placeholder || placeholderRef.current}
            className="form-control"
            ref={inputRef}
            type={isVisiblePassword ? 'text' : 'password'}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            data-cy={name}
          />
        </InputMask>
        <FieldIcon>
          {customType === 'password' &&
            (isVisiblePassword ? (
              <FiEye
                style={{ width: '30px', height: '30px', color: '#777' }}
                onClick={() => {
                  setIsVisiblePassword((prev) => !prev);
                }}
              />
            ) : (
              <FiEyeOff
                style={{ width: '30px', height: '30px', color: '#777' }}
                onClick={() => {
                  setIsVisiblePassword((prev) => !prev);
                }}
              />
            ))}

          {(errored && error) || isError ? (
            <i>
              <div className="toltip">
                <Error
                  isDisabled={!!rest.disabled || false}
                  visible={isFocused}
                  click={() => inputRef.current?.focus()}
                  title={isError || (errored && error ? error : '') || ''}
                >
                  <FiAlertCircle
                    color="#c00"
                    size={15}
                    style={{ cursor: 'pointer' }}
                    className="error-circle"
                  />
                </Error>
              </div>
            </i>
          ) : (
            <></>
          )}
        </FieldIcon>

        <Label>
          {label && (
            <h4>
              {mandatory && <span style={{ color: '#c00' }}> *</span>}
              {label}
            </h4>
          )}
        </Label>
      </Container>
      {showDisplayPass && (
        <PasswordStrengthBar
          password={text}
          scoreWordStyle={{ color: '#000000', fontWeight: 'bold' }}
          barColors={barColors}
          scoreWords={scoreWords}
          shortScoreWord="Muito Fraco"
        />
      )}
    </>
  );
};

export default DrInput;
