// @ts-nocheck
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useHistory, useLocation } from 'react-router-dom';
import Attendances from '../../components/dr-attendances';
import Button from '../../components/dr-button';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import HelmetComponent from '../../components/dr-helmet-component';
import { useAuth } from '../../hooks/auth';
import { BgConsulta } from '../../styles/bg-consulta';
import { ButtonColumn } from '../../styles/button-column';
import { CardBlank } from '../../styles/card-blank';
import { TextBanner } from '../../styles/text-banner';
import {
  handleGetTokenNotification,
  scrollPositionTop,
} from '../../utils/bibli';
import NewsContainer from './dr-news-container';

import DrCardSchedule from '../../components/dr-card-schedule';
import DrModal from '../../components/dr-modal';
import Plan from '../../components/dr-plan';
import DrShowSpecialities from '../../components/dr-show-specialities';
import { useToast } from '../../hooks/toast';
import { PageTitle } from '../../styles/page-title';
import {
  onWheel,
  scrollVisibilityApiType,
} from '../ScheduleAttendance/dr-card-attendance-schedule';
import { Arrow } from '../ScheduleAttendance/dr-card-attendance-schedule/styled';

const screenWidth = window.screen.width;

const LeftArrowPlan = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      hidden={isFirstItemVisible}
      aria-hidden="true"
      className="carousel-control-prev-icon"
      onClick={() =>
        scrollPrev('smooth', screenWidth >= 415 ? 'start' : undefined)
      }
    />
  );
};

const RightArrowPlan = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <Arrow
      hidden={isLastItemVisible}
      aria-hidden="true"
      className="carousel-control-next-icon"
      onClick={() =>
        scrollNext('smooth', screenWidth >= 415 ? 'end' : undefined)
      }
    />
  );
};

const LeftArrowSchedule = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      hidden={isFirstItemVisible}
      aria-hidden="true"
      className="carousel-control-prev-icon"
      onClick={() =>
        scrollPrev('smooth', screenWidth >= 415 ? 'start' : undefined)
      }
    />
  );
};

const RightArrowSchedule = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <Arrow
      hidden={isLastItemVisible}
      aria-hidden="true"
      className="carousel-control-next-icon"
      onClick={() =>
        scrollNext('smooth', screenWidth >= 415 ? 'end' : undefined)
      }
    />
  );
};

const Dashboard: React.FC = () => {
  // localStorage.removeItem('@Dr+:Datas');
  const {
    setDomainsData,
    applicationData,
    setAttendanceData,
    requestAPI,
    user,
    userFromLS,
    sendError,
    inactivityModal,
    setInactivityModal,
    handleCloseInactivityModal,
  } = useAuth();
  const { addToast } = useToast();

  const [modal, setModal] = React.useState({ showModal: false, msg: '' });
  const [position, setPosition] = React.useState(100);
  const [itemsPlan, setItemsPlan] = React.useState<any>([]);
  const [itemsSchedule, setItemsSchedule] = React.useState<any>([]);
  const history = useHistory();
  const location = useLocation<any>();

  const handleGetScheduledPatients = async () => {
    try {
      const scheduledPatients: any = [];

      if (userFromLS?.idtm_usuario) {
        const url = `/pacientesAgendados?idtm_usuario=${user.idtm_usuario}&exact=S`;

        const { data } = await requestAPI({ method: 'GET', url });

        if (data && data.length) {
          data.forEach((dados, idx) => {
            scheduledPatients.push({
              id: `element-${idx}`,
              idagenda: dados.idagenda,
              idagenda_consulta: dados.idagenda_consulta,
              idagenda_consulta_origem: dados.idagenda_consulta,
              idatendimento_paciente: dados.idatendimento_paciente,
              idconta_receber_cartao_movto: dados.idconta_receber_cartao_movto,
              idconvenio: dados.idconvenio,
              idestabelecimento_tipo_atendimento:
                dados.idestabelecimento_tipo_atendimento,
              idpessoa_paciente: dados.idpessoa_fisica,
              ie_classificacao_agenda: dados.ie_classificacao,
              ie_retorno: dados.ie_retorno,
              ie_telemedicina: dados.ie_telemedicina,
              ie_tipo_agenda: dados.ie_tipo_agenda,
              ie_tipo_atendimento: dados.ie_tipo_atendimento,
              idespecialidade: dados.idespecialidade,
              iniciar_consulta: dados.iniciar_consulta,
              idpessoa_medico: dados.idmedico,
              idpessoa_titular: dados.idpessoa_titular,
              ds_especialidade: dados.ds_especialidade,
              nm_pessoa_medico: dados.nm_medico,
              nr_conselho: dados.nr_conselho,
              uf_conselho: dados.uf_conselho,
              sg_conselho: dados.sg_conselho,
              idprofissional: dados.idprofissional,
              nm_pessoa_paciente: dados.nm_paciente,
              dt_nascimento: dados.dt_nascimento,
              dt_agenda: dados.dt_agenda,
              dt_agendamento: dados.dt_agendamento,
              ds_produto: dados.ds_produto,
              dt_atual_servidor: dados.dt_atual,
            });
          });

          setItemsSchedule(scheduledPatients);
        }
      } else {
        setItemsSchedule(scheduledPatients);
      }
    } catch (e: any) {
      sendError(e);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: e.message,
      });
    }
  };

  useEffect(() => {
    handleGetScheduledPatients();
  }, [userFromLS?.idtm_usuario]);

  useEffect(() => {
    if (
      location?.state?.msg
        ?.toUpperCase()
        .includes('NOSSO HORÁRIO DE ATENDIMENTO')
    ) {
      setModal({ showModal: true, msg: location?.state.msg });
    }
  }, [location]);

  useEffect(() => {
    moment.locale('pt-br');
    setDomainsData();
    scrollPositionTop();
    handleGetTokenNotification(user, requestAPI, undefined, sendError);
    setAttendanceData({}, true);
  }, []);

  useEffect(() => {
    if (!user || !user.ds_produto) {
      requestAPI({ method: 'GET', url: '/tmAtendimentoValor' })
        .then((resp) => {
          const its: any = {};
          if (resp && resp.assinaturas_pln) {
            its.assinaturas_pln = resp.assinaturas_pln.map((dados, idx) => ({
              ...dados,
              id: `element-${idx}`,
            }));
          }
          if (resp && resp.atendimento_vlr) {
            resp.atendimento_vlr.forEach((plano, indexPLano) => {
              if (plano.valor && plano.valor.length) {
                plano.valor.forEach((item, indexItem) => {
                  const itemId = item;
                  item.id = `element-1${indexItem + indexPLano}`;
                  return itemId;
                });
              }
              its.atendimento_vlr = resp.atendimento_vlr.map((dados) => ({
                ...dados,
              }));
            });
          }

          setItemsPlan(its);
        })
        .catch((err) => {
          sendError(err);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        });
    }
  }, [addToast, requestAPI, sendError, user?.ds_produto]);

  const savePositionPlan = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    []
  );

  const restorePositionPlan = React.useCallback(
    ({
      scrollContainer,
    }: // getItemById,
    // scrollToItem
    scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft = position;
        // scrollToItem(getItemById('test15'), 'auto');
      }
    },
    [position]
  );

  const savePositionSchedule = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    []
  );

  const restorePositionSchedule = React.useCallback(
    ({
      scrollContainer,
    }: // getItemById,
    // scrollToItem
    scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft = position;
        // scrollToItem(getItemById('test15'), 'auto');
      }
    },
    [position]
  );

  return (
    <>
      <HelmetComponent title="Home - Seja Bem Vindo(a)!" />
      <Header />
      <DrModal
        controllerModal={[modal.showModal, setModal]}
        title="Atenção"
        buttons={{
          cancel: {
            onClick: () => setModal((prev) => ({ ...prev, showModal: false })),
            title: 'OK',
          },
        }}
        content={
          <h4
            dangerouslySetInnerHTML={{
              __html: modal?.msg?.replace(/\n/g, '<br />') || '',
            }}
          />
        }
      />

      <DrModal
        controllerModal={[inactivityModal, setInactivityModal]}
        title="Atenção"
        buttons={{
          confirm: {
            onClick: () => {
              handleCloseInactivityModal();
              window.location.reload();
            },
            title: 'OK',
          },
        }}
        content={
          <h4 style={{ textAlign: 'center' }}>
            Você foi redirecionado ao início devido inatividade!
          </h4>
        }
      />

      <Container>
        <CardBlank className="mt-4 pt-3 pb-3">
          <Row>
            <Col
              lg={12}
              xl={3}
              className="d-flex flex-row justify-content-center"
            >
              <BgConsulta
                src={applicationData.valores_variaveis?.url_img_consulta}
              />
            </Col>

            <Col
              lg={12}
              xl={5}
              className="d-flex flex-row justify-content-center"
            >
              <TextBanner>
                {applicationData.valores_variaveis?.frase_consulta}
              </TextBanner>
            </Col>

            <Col
              lg={12}
              xl={4}
              className="d-flex flex-row justify-content-center"
            >
              <ButtonColumn>
                <Button
                  type="button"
                  title={applicationData.valores_variaveis?.botao_consulta}
                  color="pink"
                  onClick={async () => {
                    await setAttendanceData({}, true);
                    history.push('/types-care');
                  }}
                />
              </ButtonColumn>
            </Col>
          </Row>
        </CardBlank>
        {itemsSchedule && itemsSchedule.length > 0 && (
          <CardBlank className="mt-4 pt-3 pb-3">
            <PageTitle>Agendamentos</PageTitle>
            <ScrollMenu
              itemClassName="row m-0"
              LeftArrow={LeftArrowSchedule}
              RightArrow={RightArrowSchedule}
              onWheel={onWheel}
              onInit={restorePositionSchedule}
              onScroll={savePositionSchedule}
              options={{
                ratio: 0.9,
                rootMargin: '5px',
                threshold: 1,
              }}
            >
              {itemsSchedule.map((item: any, ind) => (
                <DrCardSchedule
                  key={`#id_${ind.toString()}`}
                  schedules={item}
                  itemId={item.id}
                />
              ))}
            </ScrollMenu>
          </CardBlank>
        )}
        <DrShowSpecialities />
        {itemsPlan &&
          Object.keys(itemsPlan).length > 0 &&
          (itemsPlan.assinaturas_pln.length > 0 ||
            itemsPlan.atendimento_vlr.length > 0) && (
            <CardBlank className="mt-4 pt-3 pb-3">
              <PageTitle>Valores</PageTitle>
              <ScrollMenu
                itemClassName="row m-0"
                LeftArrow={LeftArrowPlan}
                RightArrow={RightArrowPlan}
                onWheel={onWheel}
                onInit={restorePositionPlan}
                onScroll={savePositionPlan}
                options={{
                  ratio: 0.9,
                  rootMargin: '5px',
                  threshold: 1,
                }}
              >
                {itemsPlan.atendimento_vlr &&
                  itemsPlan.atendimento_vlr.length > 0 &&
                  itemsPlan.atendimento_vlr.map((plano: any, idx: any) =>
                    plano.valor.map((plan) => (
                      <Plan
                        dashboard={false}
                        itemId={plan.id}
                        key={`#id_${idx.toString()}`}
                        plan={{ ...plano, ...plan }}
                        loading={false}
                      />
                    ))
                  )}
                {itemsPlan.assinaturas_pln &&
                  itemsPlan.assinaturas_pln.length > 0 &&
                  itemsPlan.assinaturas_pln.map((plano, idx) => (
                    <Plan
                      dashboard
                      itemId={plano.id}
                      key={`#id_${idx.toString()}`}
                      plan={plano}
                      loading={false}
                      borderColor="#ed145b"
                    />
                  ))}
              </ScrollMenu>
            </CardBlank>
          )}

        {user ? (
          <Attendances
            title="Últimos atendimentos"
            itensPerPage="3"
            filter={false}
          />
        ) : (
          <></>
        )}

        <NewsContainer />
      </Container>
      <Footer />
    </>
  );
};

export default Dashboard;
