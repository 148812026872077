// @ts-nocheck
import moment from 'moment';
import 'moment/locale/pt-br';
import React, { useRef } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DrAlert from '../../components/dr-alert';
import DrButtonsFooter from '../../components/dr-buttons-footer';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import DrModalLoading from '../../components/dr-modal-loading';
import { useAuth } from '../../hooks/auth';
import { useIsMounted } from '../../hooks/is-mounted';
import { useToast } from '../../hooks/toast';
import { CardBlank } from '../../styles/card-blank';
import { valueParam } from '../../utils/bibli';
import DrCardAttendanceSchedule from './dr-card-attendance-schedule';
import { Circle, Legend } from './dr-card-attendance-schedule/styled';

const ScheduleAttendance: React.FC = () => {
  const {
    requestAPI,
    user,
    attendance,
    setAttendanceData,
    sendError,
    applicationData,
  } = useAuth();
  const { addToast } = useToast();

  const isMountedRef = useIsMounted();

  const firstRequest = useRef(true);

  const history = useHistory();
  const [response, setResponse] = React.useState<any>({});

  const [loading, setLoading] = React.useState(true);

  async function getData() {
    if (
      !attendance.idestabelecimento_tipo_atendimento ||
      !attendance.idespecialidade ||
      !attendance.idpessoa_medico
    ) {
      history.push({ pathname: `/dashboard` });
      return;
    }
    let url = `/agendaConsultaVagas?idestabelecimento_tipo_atendimento=${attendance.idestabelecimento_tipo_atendimento}`;
    url += `&idmedico=${attendance.idpessoa_medico}&agenda_consulta.idespecialidade=${attendance.idespecialidade}`;
    url += `&idconvenio=${attendance.idconvenio}`;
    if (attendance.idpessoa_paciente) {
      url += `&valor=S&idpessoa_fisica=${attendance.idpessoa_paciente}&idpessoa_titular=${attendance.idpessoa_titular}`;
    }
    if (Object.keys(response).length && response.dt_ultima_env) {
      url += `&dt_agenda=${response.dt_ultima_env}`;
    }
    attendance.isSchedule = 'N';
    try {
      setLoading(true);

      const resp = await requestAPI({
        method: 'GET',
        url,
      });

      firstRequest.current = false;

      if (
        resp &&
        resp.vagas &&
        resp.vagas.length &&
        resp.vagas[0].dias &&
        resp.vagas[0].dias.length
      ) {
        resp.vagas[0].dias.forEach((dia, idx) => {
          dia.dt_agenda = moment(dia.data).local().format('DD/MM/YYYY');
        });

        setResponse((prevState) => {
          if (prevState.vagas) {
            const array = prevState.vagas[0].dias.concat(resp.vagas[0].dias);

            prevState.vagas[0].dias = array;
            prevState.dt_ultima_env = resp.dt_ultima_env;
          } else {
            prevState = resp;
          }
          return { ...prevState };
        });
      } else {
        setResponse((prevState) => {
          delete prevState.dt_ultima_env;
          return { ...prevState };
        });
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });

      if (isMountedRef.current) history.push({ pathname: '/types-care' });
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    moment.locale('pt-br');
    if (!attendance) history.push({ pathname: '/types-care' });
    getData();
  }, []);

  async function reserveAttendance({ idagenda_consulta, tipo }) {
    if (!user) {
      history.push({ pathname: '/sign-in' });
      return;
    }
    try {
      await requestAPI({
        method: 'PUT',
        url: `/reservarAgenda`,
        body: {
          idagenda_consulta,
          tipo,
          idpessoa_titular: user.idpessoa_fisica,
        },
      });
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  function goToPayment(date) {
    const value = valueParam(applicationData, 223);

    if (value && value !== '-1') {
      const [hour, minutes, seconds] = value.split(':');
      const dt_agenda = moment(date);
      const currentDate = moment()
        .add(hour, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds');

      return currentDate.isAfter(dt_agenda);
    }
    return false;
  }

  async function popUpSchedule({
    setModal,
    setModalBusy,
    setModalReserved,
    setBtnLoading,
    idxBtn,
    agenda,
    weekDay,
    scheduleRef,
    setModalLogin,
  }: any) {
    try {
      if (!user) {
        setModalLogin(true);
        return;
      }

      setBtnLoading((prev: any) => {
        prev[idxBtn] = true;
        return [...prev];
      });

      const day = moment(agenda.dt_agenda).local().format('DD/MM/YYYY');
      const hour = moment(agenda.dt_agenda).local().format('HH:mm');

      if (agenda.ie_status_agenda === 'N') {
        const msg = 'Vaga ocupada! Escolha um horário livre.';
        setModalBusy({
          msg,
          showModal: true,
        });
      } else if (agenda.ie_status_agenda === 'R') {
        const msg =
          'Outra pessoa está em processo de agendamento desta vaga, pode ser que volte a ficar disponível!';
        setModalReserved({
          msg,
          showModal: true,
        });
      } else if (agenda.ie_status_agenda === 'L') {
        const msg = (
          <h4>
            Você confirma o agendamento da consulta com o médico:{' '}
            {response.vagas[0].nm_pessoa}, CRM: {response.vagas[0].nr_conselho},{' '}
            {response.vagas[0].ds_especialidade}, para o dia: {day} ({weekDay})
            às {hour}h para paciente{' '}
            {attendance.nm_paciente ? (
              <span style={{ color: '#ed145b' }}>{attendance.nm_paciente}</span>
            ) : (
              <span style={{ color: '#ed145b' }}>
                {attendance.nm_pessoa_paciente}
              </span>
            )}
            ?
          </h4>
        );

        scheduleRef.current = {
          scheduleNew: {
            ...agenda,
            ds_especialidade: response.vagas[0].ds_especialidade,
            nm_pessoa_medico: response.vagas[0].nm_pessoa,
            nr_conselho: response.vagas[0].nr_conselho,
          },
          idpessoa_paciente: attendance.idpessoa_paciente,
        };

        reserveAttendance({
          idagenda_consulta: agenda.idagenda_consulta,
          tipo: 'R',
        });

        setModal({
          msg,
          showModal: true,
        });
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    } finally {
      setBtnLoading((prev) => {
        prev[idxBtn] = false;
        return [...prev];
      });
    }
  }

  // eslint-disable-next-line consistent-return
  async function schedulePatient(data: any) {
    try {
      const maxTimeWithoutPayment = await goToPayment(
        data.scheduleNew.dt_agenda
      );

      let url = `/agendarPaciente`;
      let body: any = {
        idagenda_consulta: data.scheduleNew.idagenda_consulta,
        idagenda: data.scheduleNew.idagenda,
        idpessoa_fisica:
          attendance.idpessoa_paciente || data.scheduleNew.idpessoa_paciente,
        idconvenio: attendance.idconvenio,
        ie_forma_agendamento: 14,
        idestabelecimento_tipo_atendimento:
          attendance.idestabelecimento_tipo_atendimento,
        idpessoa_titular: user.idpessoa_fisica,
        ie_retorno_origem: attendance.ie_retorno,
      };

      if (
        (data.scheduleOld && data.scheduleOld.idagenda_consulta_origem) ||
        attendance.idagenda_consulta_origem
      ) {
        url = `/remarcarAgenda`;
        body = {
          ...body,
          idagenda_consulta_origem:
            data.scheduleOld?.idagenda_consulta_origem ||
            attendance.idagenda_consulta_origem,
        };
      }

      const schedule = await requestAPI({
        method: 'PUT',
        url,
        body,
      });

      await setAttendanceData({
        dt_agenda: data.scheduleNew.dt_agenda,
        ds_especialidade: data.scheduleNew.ds_especialidade,
        nm_pessoa_medico: data.scheduleNew.nm_pessoa_medico,
        nr_conselho: data.scheduleNew.nr_conselho,
        idagenda_consulta: data.scheduleNew.idagenda_consulta,
        ie_retorno: schedule.ie_retorno,
        ds_produto: schedule.ds_produto,
        avisos: schedule.avisos,
        idconta_receber_cartao_movto: schedule.idconta_receber_cartao_movto,
        msg_vlr_assinatura: response.msg_vlr_assinatura,
        msg_vlr_consulta: response.msg_vlr_consulta,
        dt_agendamento: schedule.dt_agendamento,
      });

      if (
        maxTimeWithoutPayment &&
        !schedule.ds_produto &&
        !schedule.idconta_receber_cartao_movto &&
        schedule.ie_retorno !== 'S'
      ) {
        await setAttendanceData({
          ...attendance,
          idagenda_consulta: data.scheduleNew.idagenda_consulta,
          isSchedule: 'S',
        });
        history.push({
          pathname: '/pay-options',
        });
        return undefined;
      }
      history.push({ pathname: '/confirm-schedule' });
      return undefined;
    } catch (err: any) {
      if (err.data) {
        data.scheduleNew.nm_pessoa_paciente = attendance.nm_paciente
          ? attendance.nm_paciente
          : attendance.nm_pessoa_paciente;

        data = {
          ...data,
          scheduleOld: {
            idagenda_consulta_origem: err.data.idagenda_consulta,
            nr_conselho: err.data.crm,
            nm_pessoa_medico: err.data.nm_medico,
            nm_pessoa_paciente: attendance.nm_paciente
              ? attendance.nm_paciente
              : attendance.nm_pessoa_paciente,
            dt_agenda: err.data.dt_agenda,
            ds_especialidade: err.data.ds_especialidade,
          },
        };

        return data;
      }

      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  const viewingVacancies = Math.floor(Math.random() * 10);

  async function handleClick() {
    if (
      attendance &&
      !attendance?.origem_cartao &&
      !attendance.idagenda_consulta
    ) {
      const { idespecialidade, ...data } = attendance;
      await setAttendanceData(data, true);
    }
    return history.goBack();
  }
  return (
    <>
      <Header />
      <Container>
        <DrModalLoading showModal={loading} text="Carregando datas..." />
        {response && response.vagas && response.vagas.length > 0 ? (
          <DrCardAttendanceSchedule
            agendas={response}
            getData={getData}
            reserveAttendance={reserveAttendance}
            popUpSchedule={popUpSchedule}
            checkin={schedulePatient}
          />
        ) : (
          firstRequest && (
            <CardBlank className="mt-3 pt-3 pb-3">
              <Row className="col-xl-12 justify-content-center pt-1 m-0">
                <Col>
                  <DrAlert
                    warning
                    title={{
                      text: 'Não foi localizada agenda para esse profissional',
                      fontSize: '14px',
                    }}
                  />
                </Col>
              </Row>
            </CardBlank>
          )
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Col md={4}>
            <div className="mt-3 p-2">
              <div className="d-flex align-items-center mb-1">
                <Circle color="#60bb46" />
                <Legend className="ml-2" color="#60bb46">
                  Livres
                </Legend>
              </div>
              <div className="d-flex align-items-center mb-1">
                <Circle color="#fcaf17" />
                <Legend className="ml-2" color="#fcaf17">
                  Reservadas
                </Legend>
              </div>
              <div className="d-flex align-items-center mb-1">
                <Circle color="#777777" />
                <Legend className="ml-2" color="#777777">
                  Ocupadas
                </Legend>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <DrButtonsFooter
              sizeCol={viewingVacancies !== 0 ? 10 : 12}
              buttonLeft={false}
              buttonRight={{
                title: 'Voltar',
                href: '/select-specialty',
              }}
            />
          </Col>
          {!loading && viewingVacancies !== 0 ? (
            <Col md={4} className="mt-4">
              <DrAlert
                className="m-0"
                style={{
                  paddingTop: '0px',
                  paddingBottom: '0px',
                }}
                warning
                title={{
                  tag: 'p',
                  text:
                    viewingVacancies === 1
                      ? `${viewingVacancies} pessoa está visualizando estas vagas`
                      : `${viewingVacancies} pessoas estão visualizando estas vagas`,
                }}
              />
            </Col>
          ) : (
            <></>
          )}
        </div>
      </Container>

      <Footer />
    </>
  );
};

export default ScheduleAttendance;
