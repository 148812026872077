// @ts-nocheck
import React from 'react';
import { Col } from 'react-bootstrap';
import { ReactComponent as MessageIcon } from '../../assets/svg/bubble-chat.svg';
import DrModalWhatsapp from '../dr-modal-whatsapp';
import { Text } from './styled';

const DrHelpChat = () => {
  const [modalWhatsapp, setModalWhatsapp] = React.useState(false);

  return (
    <Col
      className="align-items-center d-flex mt-3"
      style={{ flexDirection: 'column', cursor: 'pointer' }}
    >
      <Text className="mb-1">
        Tem alguma dúvida ou gostaria de mais informações?
      </Text>

      <div onClick={() => setModalWhatsapp(true)} role="presentation">
        <div className="icon-box-help justify-content-center ">
          <MessageIcon title="Ajuda" className="icon" fill="#60bb46" />
        </div>
      </div>

      <Text style={{ fontWeight: 700 }}>Fale conosco!</Text>
      <DrModalWhatsapp
        showModal={modalWhatsapp}
        closeModal={() => setModalWhatsapp(false)}
      />
    </Col>
  );
};

export default DrHelpChat;
