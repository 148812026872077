// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { Col } from 'react-bootstrap';
import { FlattenSimpleInterpolation } from 'styled-components';
import { useToast } from '../../hooks/toast';
import {
  BCBody,
  BCBubble,
  BCButtonChat,
  BCCardChat,
  BCCardMessages,
  BCClose,
  BCDrIconAttach,
  BCDrIconClose,
  BCDrIconFile,
  BCDrIconOpChat,
  BCDrIconSend,
  BCFile,
  BCFooter,
  BCInput,
  BCLabelFile,
  BCLargerIcon,
  BCMsg,
  BCPara,
  BCRowFooterMsg,
  BCSmallerIcon,
  BoxChat,
  DivSendingMsg,
  LoadingSendMsg,
} from './styled';

import { useAuth } from '../../hooks/auth';
import DragDrop from '../dr-dragdrop';

export interface userRoomI {
  name: string;
  urlImg: string;
  idPhysicalPerson: number;
}

export interface DrBoxChatProps {
  className?: string;
  hasIcon?: boolean;
  controllerChat?: any;
  clNmBCLargIcn?: string;
  clNmBCSamlIcn?: string;
  sendMessage: (msg: any) => void;
  messages: any[];
  downloadFile: (event: any, data: any) => void;
  sendFile: (file: any) => void;
  stateUpload: boolean;
  loadingSendMessage: boolean;
  userRoom: userRoomI;
  cssBCBody: FlattenSimpleInterpolation;
  setNewMessage: React.Dispatch<React.SetStateAction<boolean>>;
  newMessage: boolean;
}

const DrBoxChat: React.FC<DrBoxChatProps> = ({
  className,
  hasIcon,
  controllerChat,
  clNmBCLargIcn,
  clNmBCSamlIcn,
  sendMessage,
  messages,
  downloadFile,
  sendFile,
  userRoom,
  cssBCBody,
  setNewMessage,
  newMessage,
  stateUpload,
  loadingSendMessage,
}: DrBoxChatProps) => {
  const [chatActive, setChatActive] = controllerChat;

  const { addToast } = useToast();
  const [msg, setMsg] = React.useState('');

  const btnChatRef = React.useRef<HTMLButtonElement>(null);
  const cardMsgsRef = React.useRef<HTMLDivElement>(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (msg === '') return;
    sendMessage({
      type: 'text',
      message: {
        id: userRoom.idPhysicalPerson,
        sender: { uid: userRoom.idPhysicalPerson },
        data: msg,
      },
    });
    setMsg('');
  };

  const handleEnterIptChat = React.useCallback<
    React.KeyboardEventHandler<HTMLTextAreaElement>
  >((event) => {
    if (event.code === 'Enter' && !event.shiftKey && btnChatRef.current) {
      event.preventDefault();
      btnChatRef.current.click();
    }
  }, []);

  const handleDownloadFile = (event, data) => {
    downloadFile(event, data);
  };
  const { sendError } = useAuth();
  const handleSendFile = (event) => {
    const iptFile = event.target;
    try {
      if (!iptFile || !iptFile.files.length) {
        throw new Error('Arquivo não encontrado');
      }

      sendFile(iptFile.files);
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Erro ao processar o arquivo! ${error.message}`,
      });
    } finally {
      iptFile.value = null;
    }
  };

  const handleChange = (event) => {
    setMsg(event.target.value);
  };

  function renderSentTime(data) {
    return (
      <Col xs="auto">
        <BCFooter other={!isMyMessage(data)}>
          {data && data.message && data.message.timestampSend
            ? moment(data.message.timestampSend).format('DD/MM/YYYY HH:mm')
            : moment(data.dt_insert, 'YYYY-MM-DD HH:mm:ss').format(
                'DD/MM/YYYY HH:mm'
              )}
        </BCFooter>
      </Col>
    );
  }

  function renderBodyMessage(data) {
    return (data.type && data.type === 'text') ||
      (data.ds_mensagem && data.ds_mensagem !== null) ? (
      <>
        <BCPara other={!isMyMessage(data)}>
          {data.message && data.message.data
            ? data.message.data
            : data.ds_mensagem}
        </BCPara>
        <BCRowFooterMsg style={{ justifyContent: 'flex-end' }}>
          {renderSentTime(data)}
        </BCRowFooterMsg>
      </>
    ) : (
      <BCFile
        other={!isMyMessage(data)}
        onClick={(event) => {
          handleDownloadFile(event, data);
        }}
      >
        <div
          className={`d-flex align-items-center ${
            data.boolImage ? 'flex-column' : 'flex-row'
          }`}
        >
          {data.boolImage ? (
            <img
              src={data.message.data}
              className="message"
              style={{
                width: 200,
                cursor: 'pointer',
              }}
              alt={data.message.data}
            />
          ) : (
            <BCDrIconFile />
          )}

          <BCPara other={!isMyMessage(data)}>.{data.message.ext}</BCPara>
        </div>
        <BCRowFooterMsg style={{ justifyContent: 'space-between' }}>
          <Col xs="auto">
            <BCFooter other={!isMyMessage(data)}>Baixar</BCFooter>
          </Col>
          {renderSentTime(data)}
        </BCRowFooterMsg>
      </BCFile>
    );
  }

  const isMyMessage = (data) =>
    userRoom &&
    (userRoom.idPhysicalPerson === data.idpessoa_fisica ||
      userRoom.idPhysicalPerson === (data.message && data.message.sender.uid));

  const renderMessages = () => (
    <>
      {messages.map((data, i) => (
        <BCBubble
          other={!isMyMessage(data)}
          className={i > 0 ? 'mt-3' : undefined}
          key={data.message.idBd}
        >
          {renderBodyMessage(data)}
        </BCBubble>
      ))}
    </>
  );

  const blinkIconChat = !chatActive && newMessage;

  React.useEffect(() => {
    if (chatActive && cardMsgsRef.current) {
      cardMsgsRef.current.scrollTop = cardMsgsRef.current.scrollHeight;
    }
  }, [chatActive, messages]);

  React.useEffect(() => {
    if (
      messages.length &&
      isMyMessage(messages[messages.length - 1]) &&
      cardMsgsRef.current
    ) {
      cardMsgsRef.current.scrollTop = cardMsgsRef.current.scrollHeight;
    }
  }, [messages]);

  React.useEffect(() => {
    if (chatActive) {
      setNewMessage(false);
    }
  }, [chatActive]);

  return (
    <BoxChat className={className}>
      {hasIcon && (
        <>
          <BCLargerIcon
            className={clNmBCLargIcn || 'd-none d-xl-flex d-xxl-flex'}
            data-title="Enviar Mensagem"
            onClick={() => setChatActive('toggle')}
            active={chatActive}
            blink={blinkIconChat}
          >
            <BCDrIconOpChat />
          </BCLargerIcon>
          <BCSmallerIcon
            className={clNmBCSamlIcn || 'd-flex d-xl-none d-xxl-none'}
            onClick={() => {
              setChatActive('toggle');
            }}
            active={chatActive}
            blink={blinkIconChat}
          />
        </>
      )}
      <BCBody active={chatActive} cssExternal={cssBCBody}>
        <BCClose onClick={() => setChatActive(false)}>
          <BCDrIconClose />
        </BCClose>

        <BCCardChat>
          <BCCardMessages ref={cardMsgsRef}>{renderMessages()}</BCCardMessages>

          {stateUpload && (
            <DivSendingMsg>
              Enviando arquivo
              <LoadingSendMsg />
            </DivSendingMsg>
          )}

          {loadingSendMessage && (
            <DivSendingMsg>
              Enviando mensagem
              <LoadingSendMsg />
            </DivSendingMsg>
          )}
        </BCCardChat>

        <BCMsg>
          <DragDrop
            className="chatInputWrapper"
            sendFiles={(files) => {
              sendFile(files);
            }}
          >
            <form onSubmit={handleSubmit}>
              <BCInput
                onChange={handleChange}
                onKeyDown={handleEnterIptChat}
                value={msg}
              />
              <BCLabelFile>
                {/* <BCIconChat typeIcon="attachment" type="button"> */}
                <BCDrIconAttach name="attachment" />
                {/* </BCIconChat> */}
                <input
                  name="input-file"
                  type="file"
                  hidden
                  onChange={handleSendFile}
                />
              </BCLabelFile>

              <BCButtonChat ref={btnChatRef} type="submit">
                <BCDrIconSend />
              </BCButtonChat>
            </form>
          </DragDrop>
        </BCMsg>
      </BCBody>
    </BoxChat>
  );
};

DrBoxChat.defaultProps = {
  className: undefined,
  hasIcon: false,
  controllerChat: undefined,
  clNmBCLargIcn: undefined,
  clNmBCSamlIcn: undefined,
};

export default DrBoxChat;
