// @ts-nocheck
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BoxMember,
  BoxMemberName,
  BoxMemberPic,
} from '../../../components/dr-card-member/styled';
import DrImageProfile from '../../../components/dr-image-profile';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { CardBlank } from '../../../styles/card-blank';
import { PageSubtitle, PageTitle } from '../../../styles/page-title';
import {
  detectMobile,
  handleSocketConnection,
  scrollPositionTop,
} from '../../../utils/bibli';
import { msToClock } from '../../../utils/formatDates';

interface IdRoute {
  id: string;
}
const CardAttendanceNow: React.FC = () => {
  const {
    socketAuth,
    setSocketAuth,
    attendance,
    user,
    setAttendanceData,
    requestAPI,
    applicationData,
    sendError,
  } = useAuth();
  const history = useHistory();
  moment.locale('pt-br');

  const { params } = useRouteMatch<IdRoute>();
  const [order, setOrder] = useState<any>(
    (attendance && attendance.ordem) || 0
  );
  const [tmeData, setTmeData] = useState<any>(
    (attendance && attendance.tme) || ''
  );
  const { addToast } = useToast();
  const isMobile = useRef(false);
  isMobile.current = detectMobile();

  const idAttendanceRef = useRef();

  const interval = React.useRef<any>();

  const [timer, setTimer] = useState('00:00:00');

  React.useEffect(() => {
    const getDtEntrada = localStorage.getItem('dt_entrada_fila');
    if (!getDtEntrada)
      localStorage.setItem(
        'dt_entrada_fila',
        JSON.stringify(moment().locale('pt-br'))
      );
    return () => localStorage.removeItem('dt_entrada_fila');
  }, []);

  const getQueueOrderData = () => {
    if (order) {
      if (interval.current) {
        clearTimeout(interval.current);
      }
      const dt_entrada_fila = localStorage.getItem('dt_entrada_fila');
      if (dt_entrada_fila && dt_entrada_fila.length) {
        let tmeDiff = tmeData;
        if (Array.isArray(tmeDiff)) {
          [tmeDiff] = tmeDiff;
        }
        const eventTime = moment(JSON.parse(dt_entrada_fila)).add(
          tmeDiff,
          'minutes'
        );
        for (let i = 1; i < order; i += 1) {
          eventTime.add(tmeDiff, 'minutes');
        }
        interval.current = setInterval(() => {
          const timeLeftDuration = moment.duration(eventTime.diff(moment()));
          const timeLeftInMilliSec = timeLeftDuration.asMilliseconds();

          const tmeLeftForDrCall = msToClock(timeLeftInMilliSec); // Tempo Médio de Espera restante para que o médico realize a chamada do paciente

          if (tmeLeftForDrCall < '00:00:00') {
            setTimer('');
            clearInterval(interval.current);
            return;
          }
          setTimer(tmeLeftForDrCall);
        }, 1000);
      }
    }
  };

  React.useEffect(() => {
    getQueueOrderData();
    return () => clearInterval(interval.current);
  }, [order]);

  React.useEffect(() => {
    if (timer < '00:00:00') {
      setTimer('');
      clearInterval(interval.current);
    }
  }, [timer]);

  // eslint-disable-next-line consistent-return
  async function getData() {
    try {
      const respAttendance = await requestAPI({
        method: 'GET',
        url: `/atendimentoAberto/${user.idpessoa_fisica}/${attendance.idpessoa_paciente}?idestabelecimento_tipo_atendimento=${attendance.idestabelecimento_tipo_atendimento}`,
      });

      idAttendanceRef.current = respAttendance.idatendimento_paciente;

      if (!respAttendance || !Object.keys(respAttendance).length)
        history.push('/dashboard');

      if (
        respAttendance.idsocket_sala &&
        respAttendance.ds_key &&
        respAttendance.idsocket_sessao &&
        !respAttendance.dt_finalizacao
      ) {
        await setAttendanceData({
          idSocketSala: respAttendance.idsocket_sala,
          dsKey: respAttendance.ds_key,
        });
        return true;
      }
      if (respAttendance) {
        setTmeData(respAttendance?.ordem_fila?.tme?.split('.', 1));
        setOrder(respAttendance.ordem_fila.atendimentos[0]?.ordem);
        await setAttendanceData({
          ordem: respAttendance.ordem_fila.atendimentos[0]?.ordem,
          tme: respAttendance.ordem_fila.tme,
          nr_conselho: attendance.nr_conselho || respAttendance.nr_conselho,
          ds_especialidade:
            attendance.ds_especialidade || respAttendance.ds_especialidade,
          nm_pessoa_medico:
            attendance.nm_pessoa_medico || respAttendance.nm_pessoa_medico,
          url_img_medico:
            attendance.url_img_medico || respAttendance.url_img_medico,
        });
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `Ocorreu um erro ao obter os dados de atendimento, ${err.message}`,
      });
      history.push('/dashboard');
    }
  }

  const handleVisibilityChange = useCallback((ev) => {
    if (ev.visibilityState === 'hidden') {
      if (socketAuth.socket) {
        socketAuth.socket.close();
        setSocketAuth({ socket: undefined });
      }
    }
    if (ev.visibilityState === 'visible') {
      document.location.reload();
    }
  }, []);

  useEffect(() => {
    if (isMobile.current) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  useEffect(() => {
    scrollPositionTop();
    let socket;
    if (
      attendance &&
      attendance.idatendimento_paciente &&
      attendance.idSocketSala &&
      attendance.dsKey &&
      params.id !== 'returnAttendanceError'
    ) {
      history.push({ pathname: `/stream-page` });
    } else {
      if (socketAuth.socket) {
        socketAuth.socket.close();
      }
      getData().then((resp) => {
        if (resp) {
          history.push('/stream-page');
          return;
        }
        socket = handleSocketConnection(
          attendance.idatendimento_paciente || idAttendanceRef.current,
          user,
          false,
          history,
          addToast,
          sendError,
          applicationData
        );
        if (socket) {
          socket?.on('connect_error', () => {
            addToast({
              type: 'error',
              title: 'Ops!',
              description:
                'Erro ao tentar conectar com o servidor! Tente novamente',
            });
            history.goBack();
          });

          socket?.on('atualiza-fila', async (ordemAtendimento) => {
            let { idatendimento_finalizado } = ordemAtendimento;
            if (idatendimento_finalizado)
              idatendimento_finalizado = Number(idatendimento_finalizado);

            const atendimento = ordemAtendimento.atendimentos.find(
              (atend) =>
                atend.idatendimento_paciente ===
                attendance.idatendimento_paciente
            );
            if (atendimento) {
              setTmeData(ordemAtendimento.tme?.split('.', 1));
              setOrder(atendimento?.ordem);
              await setAttendanceData({
                ordem: atendimento?.ordem,
                tme: [ordemAtendimento.tme],
              });
            } else if (
              idatendimento_finalizado &&
              (idatendimento_finalizado === attendance.idatendimento_paciente ||
                idatendimento_finalizado === idAttendanceRef.current)
            ) {
              addToast({
                type: 'error',
                title: 'Ops!',
                description: 'Médico finalizou o atendimento!',
              });
              history.push('/dashboard');
            }
          });

          socket?.on('chamar-paciente', (idSocket) => {
            socket.emit('paciente-aceitou-chamada', idSocket);
          });

          socket?.on('convite-sala-paciente', ({ dsKey, idSocketSala }) => {
            setSocketAuth((prev) => ({ ...prev, dsKey, idSocketSala }));

            history.push(`/stream-page`);
          });

          setSocketAuth((prev) => ({ ...prev, socket }));
        }
      });
    }

    return () => {
      socket?.removeAllListeners('atualiza-fila');
    };
  }, [applicationData]);

  return (
    <Container className="mt-4">
      <CardBlank className="pt-3 pb-3">
        {tmeData && order && order > 1 && timer && timer >= '00:00:00' ? (
          <>
            <PageSubtitle textCenter>
              {`Você é o ${order}º da fila`}
            </PageSubtitle>
            <PageTitle>
              No momento todos os nossos profissionais estão em atendimento
            </PageTitle>
            <PageSubtitle textCenter>
              Tempo estimado para início do atendimento:
            </PageSubtitle>

            <PageSubtitle textCenter color="#ed145b">
              {timer}
            </PageSubtitle>
          </>
        ) : (
          <PageSubtitle textCenter color="#ed145b">
            Em instantes você será atendido pelo Dr+
          </PageSubtitle>
        )}

        {attendance &&
        attendance.nm_pessoa_medico &&
        attendance.nr_conselho &&
        attendance.ds_especialidade ? (
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <BoxMember style={{ margin: 5 }} noHover>
                <BoxMemberPic>
                  <DrImageProfile
                    source={
                      attendance.url_img_medico ||
                      (applicationData &&
                        applicationData.valores_parametros &&
                        applicationData.valores_parametros['106'])
                    }
                    alt="foto de perfil"
                    width={100}
                    height={100}
                    style={{
                      borderRadius: '50%',
                      borderStyle: 'none',
                      padding: '3px',
                      alignSelf: 'center',
                      margin: '15px auto 15px 15px',
                    }}
                  />
                </BoxMemberPic>
                <div
                  style={{
                    alignSelf: 'center',
                  }}
                >
                  <BoxMemberName>
                    <strong>{attendance.nm_pessoa_medico}</strong>
                  </BoxMemberName>
                  <BoxMemberName>{`CRM: ${attendance.nr_conselho}`}</BoxMemberName>
                  <BoxMemberName>{attendance.ds_especialidade}</BoxMemberName>
                </div>
              </BoxMember>
            </Col>
          </Row>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <></>
        )}
      </CardBlank>
      <CardBlank className="mt-3 pt-3 pb-3">
        <PageTitle style={{ textAlign: 'center' }}>
          Por favor, não saia desta tela até que seja atendido!
        </PageTitle>
      </CardBlank>
      {applicationData.valores_variaveis &&
        applicationData.valores_variaveis.url_checklist &&
        applicationData.valores_variaveis.url_checklist !== '-1' && (
          <CardBlank className="mt-3 text-center">
            <img
              style={{ maxWidth: '100%' }}
              src={applicationData.valores_variaveis.url_checklist}
              alt=""
            />
          </CardBlank>
        )}
    </Container>
  );
};
export default CardAttendanceNow;
