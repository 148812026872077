// @ts-nocheck
import moment from 'moment';
import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useHistory } from 'react-router-dom';
import DrButtonLoading from '../../../components/dr-button-loading';
import DrCardHeaderSchedule from '../../../components/dr-card-header-schedule';
import {
  BoxMemberName,
  BoxMemberPic,
} from '../../../components/dr-card-member/styled';
import HelmetComponent from '../../../components/dr-helmet-component';
import DrImageProfile from '../../../components/dr-image-profile';
import DrModal from '../../../components/dr-modal';
import { PageSubTitle } from '../../../components/dr-news-details/styled';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import { upperCaseLetter, valueParam } from '../../../utils/bibli';
import { getUserDeviceTimeZone } from '../../../utils/util';
import './hideScrollbar.css';
import { Arrow, ContainerDiv, PadraoText, TextContent } from './styled';

export type scrollVisibilityApiType = React.ContextType<
  typeof VisibilityContext
>;

interface ButtonData {
  days: any;
  setModal: React.Dispatch<
    React.SetStateAction<{
      showModal: boolean;
      msg: string;
    }>
  >;
  setModalBusy: React.Dispatch<
    React.SetStateAction<{
      showModal: boolean;
      msg: string;
    }>
  >;
  setModalReserved: React.Dispatch<
    React.SetStateAction<{
      showModal: boolean;
      msg: string;
    }>
  >;
  popUpSchedule: (data: any) => Promise<any>;
  itemId: any;
  scheduleRef: React.MutableRefObject<any>;
  setModalLogin: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ScheduleTypes {
  agendas: any;
  getData: () => Promise<any>;
  popUpSchedule: (data: any) => Promise<any>;
  reserveAttendance: (data: any) => Promise<any>;
  checkin: (data: any) => Promise<any>;
}

const Button: React.FC<ButtonData> = ({
  days,
  setModal,
  popUpSchedule,
  itemId,
  scheduleRef,
  setModalLogin,
  setModalBusy,
  setModalReserved,
}) => {
  React.useEffect(() => {
    moment.locale('pt-br');
  }, []);

  const [btnLoading, setBtnLoading] = React.useState(() =>
    days.horarios.map(() => false)
  );

  const weekDayRef = React.useRef(
    upperCaseLetter(
      moment(days.dia, 'DD/MM/YYYY').local().format('ddd'),
      '-',
      '-'
    )
  );

  const isToday = upperCaseLetter(
    moment().local().format('DD/MM/YYYY'),
    '-',
    '-'
  );

  const isTomorrow = upperCaseLetter(
    moment().add(1, 'days').local().format('DD/MM/YYYY'),
    '-',
    '-'
  );

  if (days.dia === isToday) {
    weekDayRef.current = 'Hoje';
  } else if (days.dia === isTomorrow) {
    weekDayRef.current = 'Amanhã';
  }

  return (
    <ContainerDiv>
      <CardBlank className=" justify-content-center m-4">
        <PageSubTitle
          style={{
            textAlign: 'center',
            marginBottom: 0,
            color: '#3f56a5',
          }}
        >
          {`${days.dia}${'\n'}` || ''}
        </PageSubTitle>
        <PageSubTitle
          style={{ textAlign: 'center', marginBottom: 5, color: '#3f56a5' }}
        >{`(${weekDayRef.current})`}</PageSubTitle>

        <Container
          className="pl-3 pr-3 pb-3 pt-2 scroll"
          style={{
            height: '45vh',
            width: 150,
          }}
        >
          {days.horarios.map((hr, idx) => {
            let textColor = '#555';
            let colorButton = '#777777';
            if (hr.ie_status_agenda === 'L') {
              colorButton = '#60bb46';
              textColor = '#ffff';
            } else if (hr.ie_status_agenda === 'R') {
              textColor = '#999';
              colorButton = '#fcaf17';
            }
            return (
              <div key={`#id_${itemId}${idx.toString()}`} className="mb-2">
                <DrButtonLoading
                  textDecoration={
                    hr.ie_status_agenda !== 'R' && hr.ie_status_agenda !== 'L'
                  }
                  title={moment(hr.dt_agenda).local().format('HH:mm')}
                  type="button"
                  color={colorButton}
                  titleColor={textColor}
                  loading={btnLoading[idx]}
                  onClick={async () =>
                    popUpSchedule({
                      setModal,
                      setBtnLoading,
                      idxBtn: idx,
                      agenda: hr,
                      weekDay: weekDayRef.current,
                      scheduleRef,
                      setModalLogin,
                      setModalBusy,
                      setModalReserved,
                    })
                  }
                />
              </div>
            );
          })}
        </Container>
      </CardBlank>
    </ContainerDiv>
  );
};

export function onWheel(
  apiObj: scrollVisibilityApiType,
  ev: React.WheelEvent
): void {
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}

const LeftArrow = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      hidden={isFirstItemVisible}
      aria-hidden="true"
      className="carousel-control-prev-icon"
      onClick={() => scrollPrev()}
    />
  );
};

const RightArrow = (setIsLast) => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  React.useEffect(() => {
    setIsLast(isLastItemVisible);
  }, [isLastItemVisible]);

  return (
    <Arrow
      aria-hidden="true"
      className="carousel-control-next-icon"
      onClick={() => {
        scrollNext();
      }}
    />
  );
};

const DrCardAttendanceSchedule: React.FC<ScheduleTypes> = (
  Props: ScheduleTypes
) => {
  const {
    requestAPI,
    user,
    attendance,
    setAttendanceData,
    sendError,
    applicationData,
  } = useAuth();

  const { addToast } = useToast();
  const { agendas, getData, reserveAttendance, popUpSchedule, checkin } = Props;
  const [modal, setModal] = React.useState({
    showModal: false,
    msg: '',
  });
  const [modalBusy, setModalBusy] = React.useState({
    showModal: false,
    msg: '',
  });
  const [modalReserved, setModalReserved] = React.useState({
    showModal: false,
    msg: '',
  });

  const history = useHistory();
  const [modalReschedule, setModalReschedule] = React.useState(false);
  const [btnModalLoading, setBtnModalLoading] = React.useState(false);
  const [modalNewAttendance, setModalNewAttendance] = React.useState(false);
  const [modalLogin, setModalLogin] = React.useState(false);
  const scheduleRef = React.useRef({} as any);
  const [isLast, setIsLast] = React.useState<boolean>(false);
  const [position, setPosition] = React.useState(100);
  const [items, setItems] = React.useState<any>([]);
  const [isBtnLoading, setIsBtnLoading] = React.useState({
    loadingConfirm: false,
    loadingChange: false,
    loadingCancel: false,
  });

  const timeZone = getUserDeviceTimeZone();

  React.useEffect(() => {
    if (agendas && agendas.vagas.length) {
      agendas.vagas[0].dias.forEach((dia, idx) => {
        dia.id = `element-0${idx}`;
        dia.dt_agenda = moment(dia.data).local().format('DD/MM/YYYY');
      });
      setItems(agendas.vagas[0]);
    }
  }, [agendas]);

  React.useEffect(() => {
    if (isLast && agendas.dt_ultima_env) {
      getData();
    }
  }, [isLast]);

  const savePosition = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    []
  );

  const restorePosition = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft = position;
      }
    },
    [position]
  );
  function goToPayment(date) {
    const value = valueParam(applicationData, 223);

    if (value && value !== '-1') {
      const [hour, minutes, seconds] = value.split(':');
      const dt_agenda = moment(date);
      const currentDate = moment()
        .add(hour, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds');

      return currentDate.isAfter(dt_agenda);
    }
    return false;
  }
  // eslint-disable-next-line consistent-return
  async function schedulePatient(data: any) {
    try {
      setBtnModalLoading(true);
      const maxTimeWithoutPayment = await goToPayment(
        data.scheduleNew.dt_agenda
      );
      scheduleRef.current.ie_nova_consulta =
        data.ie_nova_consulta ||
        scheduleRef.current.ie_nova_consulta ||
        (attendance.ie_retorno === 'N' ? 'S' : 'N');

      let url = `/agendarPaciente`;
      let body: any = {
        idagenda_consulta: data.scheduleNew.idagenda_consulta,
        idagenda: data.scheduleNew.idagenda,
        idpessoa_fisica:
          attendance.idpessoa_paciente || data.scheduleNew.idpessoa_paciente,
        idconvenio: attendance.idconvenio,
        ie_forma_agendamento: 14,
        idestabelecimento_tipo_atendimento:
          attendance.idestabelecimento_tipo_atendimento,
        idpessoa_titular: user.idpessoa_fisica,
        ie_nova_consulta: scheduleRef.current.ie_nova_consulta,
        idagenda_retorno:
          data.idagenda_retorno || scheduleRef.current.idgenda_retorno || null,
        ie_retorno_origem: attendance.ie_retorno,
      };

      if (
        (data.scheduleOld && data.scheduleOld.idagenda_consulta_origem) ||
        (attendance.idagenda_consulta_origem &&
          !(body.ie_nova_consulta === 'S'))
      ) {
        url = `/remarcarAgenda`;
        body = {
          ...body,
          idagenda_consulta_origem:
            data.scheduleOld?.idagenda_consulta_origem ||
            attendance.idagenda_consulta_origem,
        };
      }

      const schedule = await requestAPI({
        method: 'PUT',
        url,
        body,
      });
      await setAttendanceData({
        dt_agenda: data.scheduleNew.dt_agenda,
        ds_especialidade: data.scheduleNew.ds_especialidade,
        nm_pessoa_medico: data.scheduleNew.nm_pessoa_medico,
        nr_conselho: data.scheduleNew.nr_conselho,
        idagenda_consulta: data.scheduleNew.idagenda_consulta,
        ie_retorno: schedule.ie_retorno,
        ds_produto: schedule.ds_produto,
        avisos: schedule.avisos,
        idconta_receber_cartao_movto: schedule.idconta_receber_cartao_movto,
        msg_vlr_assinatura: agendas.msg_vlr_assinatura,
        msg_vlr_consulta: agendas.msg_vlr_consulta,
        dt_agendamento: schedule.dt_agendamento,
      });

      if (
        maxTimeWithoutPayment &&
        !schedule.ds_produto &&
        !schedule.idconta_receber_cartao_movto &&
        schedule.ie_retorno !== 'S'
      ) {
        await setAttendanceData({
          ...attendance,
          idagenda_consulta: data.scheduleNew.idagenda_consulta,
          isSchedule: 'S',
        });
        history.push({
          pathname: '/pay-options',
        });
        return undefined;
      }
      setBtnModalLoading(false);
      history.push({ pathname: '/confirm-schedule' });
      return undefined;
    } catch (err: any) {
      setBtnModalLoading(false);
      if (
        err.message.includes('Tempo de retorno excedido!') &&
        attendance.ie_retorno !== 'N'
      ) {
        setBtnModalLoading(false);
        scheduleRef.current.idagenda_retorno =
          attendance.idagenda_consulta_origem;
        setModalNewAttendance(true);
      } else {
        if (err.data) {
          data.scheduleNew.nm_pessoa_paciente = attendance.nm_paciente
            ? attendance.nm_paciente
            : attendance.nm_pessoa_paciente;

          data = {
            ...data,
            scheduleOld: {
              idagenda_consulta_origem: err.data.idagenda_consulta,
              nr_conselho: err.data.crm,
              nm_pessoa_medico: err.data.nm_medico,
              nm_pessoa_paciente: attendance.nm_paciente
                ? attendance.nm_paciente
                : attendance.nm_pessoa_paciente,
              dt_agenda: err.data.dt_agenda,
              ds_especialidade: err.data.ds_especialidade,
            },
          };
          scheduleRef.current.scheduleOld = {
            idagenda_consulta_origem: err.data.idagenda_consulta,
            nr_conselho: err.data.crm,
            nm_pessoa_medico: err.data.nm_medico,
            nm_pessoa_paciente: attendance.nm_paciente
              ? attendance.nm_paciente
              : attendance.nm_pessoa_paciente,
            dt_agenda: err.data.dt_agenda,
            ds_especialidade: err.data.ds_especialidade,
          };
          return data;
        }
        setBtnModalLoading(false);
        if (err.message.includes('Paciente já possui agendamento.')) {
          setModalReschedule(true);
        } else {
          setBtnModalLoading(false);
          sendError(err);
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
        }
      }
    }
  }

  const handleConfirmButton = async () => {
    setIsBtnLoading((prev: any) => ({ ...prev, loadingConfirm: true }));
    setModal((prev) => ({ ...prev, showModal: false }));
    const data = await schedulePatient({ ...scheduleRef.current });

    if (data) {
      scheduleRef.current = data;
      setModalReschedule(true);
    }
    setIsBtnLoading((prev: any) => ({ ...prev, loadingConfirm: false }));
  };

  const handleChangePatientButton = async () => {
    setIsBtnLoading((prev: any) => ({ ...prev, loadingChange: true }));
    await reserveAttendance({
      idagenda_consulta: scheduleRef.current?.scheduleNew?.idagenda_consulta,
      tipo: 'L',
    });

    history.push('/select-to-attendance');
    setIsBtnLoading((prev: any) => ({ ...prev, loadingChange: false }));
  };

  const handleCancelButton = async () => {
    setIsBtnLoading((prev: any) => ({ ...prev, loadingCancel: true }));
    if (scheduleRef.current?.scheduleNew?.ie_status_agenda === 'L') {
      await reserveAttendance({
        idagenda_consulta: scheduleRef.current?.scheduleNew?.idagenda_consulta,
        tipo: 'L',
      });
    }

    setModalBusy((prev) => ({
      showModal: false,
      msg: '',
    }));

    setModalReserved((prev) => ({
      showModal: false,
      msg: '',
    }));

    setModal((prev) => ({
      showModal: false,
      msg: '',
    }));

    if (scheduleRef.current?.scheduleOld) setModalReschedule(false);
    setIsBtnLoading((prev: any) => ({ ...prev, loadingCancel: false }));
  };

  function cancelationMsg() {
    const value = valueParam(applicationData, 223);
    if (value && value !== 1) {
      const [hour, minutes, seconds] = value.split(':');
      const actualDay = moment().local().format('DD/MM/YYYY');

      const dt_agendamento = scheduleRef.current?.scheduleNew?.dt_agenda;

      const sumHrParameter = moment()
        .add(hour, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds');

      const sumHrParameterFormated = moment(sumHrParameter).format('HH:mm');

      if (sumHrParameter.isAfter(dt_agendamento)) {
        return (
          <h6>
            Não efetuando o pagamento, agendamento será cancelado
            automaticamente.
          </h6>
        );
      }
      return (
        <div>
          <h6>
            Não efetuando o pagamento até{' '}
            <h6
              style={{
                color: '#ed145b',
                display: 'inline',
                fontWeight: 'bold',
              }}
            >
              {`${actualDay} às ${sumHrParameterFormated}h`}
            </h6>
            , agendamento será cancelado automaticamente
          </h6>
        </div>
      );
    }
    return '';
  }

  const disableTheButton = () =>
    Object.values(isBtnLoading).find((btnLoading) => btnLoading === true);

  return (
    <Container>
      <HelmetComponent title="Agendamento para atendimento" />
      {/* <DrModalLoading showModal={loading} text="Carregando datas..." /> */}
      <DrModal
        controllerModal={[modalLogin, setModalLogin]}
        title="Atenção"
        buttons={{
          confirm: {
            title: 'Login/Cadastro',
            onClick: () => {
              history.push('/sign-in');
            },
          },
          cancel: {
            title: 'Cancelar',
            onClick: () => {
              setModalLogin(false);
            },
          },
        }}
        content={<h4>Necessário realizar Login ou Cadastro!</h4>}
      />
      <DrModal
        onHide={async () => handleCancelButton()}
        controllerModal={[modal.showModal, setModal]}
        title="Confirmar agendamento"
        buttons={{
          confirm: {
            onClick: async () => handleConfirmButton(),
            title: 'Confirmar',
            loading: isBtnLoading.loadingConfirm,
            disabled: disableTheButton(),
          },

          changePatient:
            attendance.idagenda_consulta || attendance.ie_retorno === 'S'
              ? undefined
              : {
                  onClick: async () => handleChangePatientButton(),
                  title: 'Alterar paciente',
                  loading: isBtnLoading.loadingChange,
                  disabled: disableTheButton(),
                },

          cancel: {
            onClick: async () => handleCancelButton(),
            title: 'Cancelar',
            loading: isBtnLoading.loadingCancel,
            disabled: disableTheButton(),
          },
        }}
        content={
          <>
            <h4 style={{ textAlign: 'center' }}>{modal.msg || ''}</h4>

            {!attendance.idconta_receber_cartao_movto &&
            !agendas.ds_produto &&
            attendance.ie_retorno !== 'S' ? (
              <>
                {(agendas.msg_vlr_assinatura || agendas.msg_vlr_consulta) && (
                  <h6
                    style={{ textAlign: 'start', color: '#3f56a5', margin: 0 }}
                  >
                    Efetivação da consulta só acontecerá após pagamento:
                  </h6>
                )}
                {agendas.msg_vlr_assinatura &&
                  agendas.msg_vlr_assinatura !== '' && (
                    <h6
                      style={{
                        textAlign: 'start',
                        color: '#ed145b',
                        margin: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {agendas.msg_vlr_assinatura}
                    </h6>
                  )}
                {agendas.msg_vlr_consulta &&
                  agendas.msg_vlr_consulta !== '' && (
                    <h6
                      style={{
                        textAlign: 'start',
                        color: '#ed145b',
                        margin: 0,
                        fontWeight: 'bold',
                      }}
                    >
                      {agendas.msg_vlr_consulta}
                    </h6>
                  )}
                <br />
                <h6 style={{ textAlign: 'start', color: '#3f56a5', margin: 0 }}>
                  {cancelationMsg()}
                </h6>
              </>
            ) : (
              <></>
            )}
          </>
        }
      />
      <DrModal
        onHide={async () => handleCancelButton()}
        controllerModal={[modalBusy.showModal, setModalBusy]}
        title="Atenção"
        buttons={{
          cancel: {
            onClick: async () => handleCancelButton(),
            title: 'Ok',
          },
        }}
        content={<h4 style={{ textAlign: 'center' }}>{modalBusy.msg || ''}</h4>}
      />
      <DrModal
        onHide={async () => handleCancelButton()}
        controllerModal={[modalReserved.showModal, setModalReserved]}
        title="Atenção"
        buttons={{
          cancel: {
            onClick: async () => handleCancelButton(),
            title: 'Ok',
          },
        }}
        content={
          <h4 style={{ textAlign: 'center' }}>{modalReserved.msg || ''}</h4>
        }
      />
      <DrModal
        onHide={async () => handleCancelButton()}
        controllerModal={[modalReschedule, setModalReschedule]}
        title="Ops! Já tem um agendamento em andamento."
        buttons={{
          confirm: {
            onClick: async () => handleConfirmButton(),
            title: 'Confirmar',
            loading: isBtnLoading.loadingConfirm,
            disabled: disableTheButton(),
          },

          cancel: {
            onClick: async () => handleCancelButton(),
            title: 'Cancelar',
            loading: isBtnLoading.loadingCancel,
            disabled: disableTheButton(),
          },
        }}
        content={
          <>
            <DrCardHeaderSchedule
              headerData={scheduleRef.current?.scheduleOld}
            />
            <Row
              style={{
                marginTop: 20,
                color: '#ed145b',
                borderBottom: '2px solid #7777771a',
                fontSize: 20,
              }}
            />
            <PageTitle>Confirma o Reagendamento para o novo horário?</PageTitle>
            <DrCardHeaderSchedule
              headerData={scheduleRef.current?.scheduleNew}
            />
          </>
        }
      />
      <DrModal
        controllerModal={[modalNewAttendance, setModalNewAttendance]}
        title="Atenção!"
        buttons={{
          confirm: {
            onClick: () => setModalNewAttendance(false),
            title: 'Ok',
          },
        }}
        content={
          <TextContent>
            <PadraoText>
              Este agendamento está fora do período de retorno. Data de
              agendamento não pode ser maior que a data de retorno!
            </PadraoText>
          </TextContent>
        }
      />
      <CardBlank className="pt-3 overflowed">
        <PageTitle>Selecione um horário</PageTitle>
        <div className="d-flex align-items-center justify-content-center">
          <BoxMemberPic>
            <DrImageProfile
              source={items.url_img || ''}
              alt="foto de perfil"
              width={100}
              height={100}
              style={{
                borderRadius: '50%',
                borderStyle: 'none',
                padding: '3px',
                alignSelf: 'center',
                margin: '15px auto 15px 15px',
              }}
            />
          </BoxMemberPic>
          <div style={{ height: 60 }}>
            <BoxMemberName style={{ marginBottom: 0 }}>
              <strong> {items.nm_pessoa || ''}</strong>
            </BoxMemberName>
            <BoxMemberName style={{ marginBottom: 0 }}>
              {`${items.sg_conselho}: ${items.nr_conselho}` || ''}
            </BoxMemberName>
            <BoxMemberName>{items.ds_especialidade || ''}</BoxMemberName>
          </div>
        </div>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={() => RightArrow(setIsLast)}
          onWheel={onWheel}
          onInit={restorePosition}
          onScroll={savePosition}
          options={{
            ratio: 0.9,
            rootMargin: '5px',
            threshold: 1,
          }}
        >
          {items &&
            items.dias &&
            items.dias.length &&
            items.dias.map((dia: any, idx: number) => (
              <Button
                days={dia}
                itemId={dia.id}
                setModal={setModal}
                scheduleRef={scheduleRef}
                popUpSchedule={popUpSchedule}
                setModalLogin={setModalLogin}
                setModalBusy={setModalBusy}
                setModalReserved={setModalReserved}
              />
            ))}
        </ScrollMenu>
      </CardBlank>
      <div className="d-flex mt-2">
        <strong style={{ color: '#ed145b' }}>Fuso Horário:</strong>
        <BoxMemberName
          style={{ color: '#ed145b', marginLeft: 4, fontWeight: 'normal' }}
        >
          {timeZone}
        </BoxMemberName>
      </div>
    </Container>
  );
};

export default DrCardAttendanceSchedule;
