// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { ArrayCreditCard, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { BoxCards } from '../../styles/box-cards';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';

import DrAlert from '../../components/dr-alert';
import Button from '../../components/dr-button';
import Loading from '../../components/dr-card-loading';
import DrModal from '../../components/dr-modal';
import { CardCards } from '../../styles/card-cards';
import { CardCardsExclude } from '../../styles/card-cards_exclude';
import { scrollPositionTop } from '../../utils/bibli';

const PaymentsMethods: React.FC = () => {
  const { user, requestAPI, setAttendanceData, sendError } = useAuth();

  const [creditCards, setCreditCards] = useState<ArrayCreditCard>([]);
  const history = useHistory();
  const [modal, setModal] = useState<any>({ showModal: false, cardId: 0 });
  const [loading, setLoading] = useState(true);

  const { addToast } = useToast();

  function handleGetCreditCards() {
    scrollPositionTop();
    requestAPI({
      method: 'GET',
      url: `/tmUsuarioCartaoUsu?idpessoa_fisica=${user.idpessoa_fisica}`,
    })
      .then((resp) => {
        setCreditCards(resp);
      })
      .catch((err: any) => {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    handleGetCreditCards();
  }, []);

  async function setInactive(id: number) {
    setLoading(true);

    requestAPI({
      method: 'PUT',
      url: `/tmUsuarioCartaoInactive/${id}`,
    })
      .then((resp) => {
        const result = creditCards.filter(
          ({ idtm_usuario_cartao }) => idtm_usuario_cartao !== id
        );

        setCreditCards([...result] || []);
      })
      .catch((err: any) => {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });

    modal.showModal = false;
  }

  return (
    <>
      <Header />
      {loading ? (
        <Loading />
      ) : (
        <>
          <Container className="mb-3 mt-3">
            <Row className="col-xl-12 justify-content-center row-btn m-0 p-0">
              <Col xl={4} md={12} className="p-0">
                <Button
                  spaceBetween
                  type="button"
                  color="blue"
                  href="/add-card"
                  title="Novo Cartão"
                />
              </Col>
              <Col xl={4} md={12} className="p-0">
                <Button
                  spaceBetween
                  type="button"
                  color="pink"
                  onClick={async () => {
                    await setAttendanceData({}, true);
                    history.push('/types-care');
                  }}
                  title="Nova Consulta"
                />
              </Col>
              <Col xl={4} md={12} className="p-0">
                <Button
                  spaceBetween
                  type="button"
                  color="dark-grey"
                  href="/profile"
                  title="Voltar"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <CardBlank className="pt-3 pb-3">
              <DrModal
                controllerModal={[modal.showModal, setModal]}
                title="Confirmação"
                buttons={{
                  confirm: {
                    title: 'Confirmar',
                    onClick: () => {
                      setInactive(modal.cardId);
                      setModal({ showModal: false });
                    },
                  },
                  cancel: {
                    title: 'Voltar',
                    onClick: () => setModal({ showModal: false }),
                  },
                }}
                content={<h4>Deseja realmente remover este cartão?</h4>}
              />
              <PageTitle>Opções de Pagamento</PageTitle>
              <Row>
                {creditCards && creditCards.length ? (
                  <>
                    {creditCards.map((card, index) => (
                      <Col md={12} xl={4} key={card.idtm_usuario_cartao}>
                        <BoxCards>
                          <CardCards id="CardCards">
                            <Cards
                              key={card.idtm_usuario_cartao}
                              cvc="hello"
                              expiry={card.ds_validade}
                              name={card.nm_titular}
                              number={card.ds_nrcartao}
                              locale={{ valid: 'validade' }}
                              placeholders={{ name: 'Seu nome aqui' }}
                            />

                            <CardCardsExclude
                              onClick={() =>
                                setModal({
                                  showModal: true,
                                  cardId: card.idtm_usuario_cartao,
                                })
                              }
                            >
                              Excluir Cartão
                            </CardCardsExclude>
                          </CardCards>
                        </BoxCards>
                      </Col>
                    ))}
                  </>
                ) : (
                  <Row className="col-xl-12 justify-content-center pt-1 m-0">
                    <Col>
                      <DrAlert
                        warning
                        title={{
                          tag: 'h4',
                          text: '',
                        }}
                      >
                        <p>Você não possui cartões cadastrados!</p>
                      </DrAlert>
                    </Col>
                  </Row>
                )}
              </Row>
            </CardBlank>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
};

export default PaymentsMethods;
