// @ts-nocheck
import React from 'react';
import { Container } from 'react-bootstrap';
import DrIcon from '../dr-icon-font';
import { ContainerLoad, DivLoading } from './styled';

interface DivLoadingProps {
  ratio?: number;
  notCentralize?: boolean;
  className?: string;
  drColor?: string;
  maisColor?: string;
}

interface LoadingProps extends DivLoadingProps {
  mensagem?: string | false;
  customLoading?: boolean;
}
const DrDivLoading: React.FC<DivLoadingProps> = ({
  ratio = 1,
  notCentralize = false,
  className = undefined,
  drColor = '#ed1164',
  maisColor = '#fcaf17',
}: DivLoadingProps) => (
  <Container className={`${className || ''}pt-3`}>
    <DivLoading
      notCentralize={notCentralize}
      className={className}
      ratio={ratio}
    >
      <DrIcon name="dr" color={drColor} fontSize={`${ratio * 64}px`} />
      <DrIcon name="mais" color={maisColor} fontSize={`${ratio * 32}px`} />
    </DivLoading>
  </Container>
);

const Loading: React.FC<LoadingProps> = ({
  /* Essa constante permite passar uma mensagem junto com o loading (ex: <Loading mensagem="Aguarde um momento" />) */
  mensagem,
  /* Se não passar essa constante o loading irá considerar a altura da tela para encontrar o meio e se encaixar no centro
  Caso queira que o loading se encaixe em um lugar especifico (por exemplo, substituir somente um botão, card... etc),
  passe essa constante no componente(ex: <Loading customLoading />) */
  customLoading,
  ...rest
}: LoadingProps) => {
  const elemLoading = (
    <>
      <DrDivLoading {...rest} />
      {mensagem && <h2 className="msgLoading">{mensagem}</h2>}
    </>
  );
  return (
    <>
      {customLoading ? (
        elemLoading
      ) : (
        <ContainerLoad>{elemLoading}</ContainerLoad>
      )}
    </>
  );
};

Loading.defaultProps = {
  mensagem: false,
  customLoading: false,
};

export default Loading;
