// @ts-nocheck
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ArrayMemberGroup, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import DrAlert from '../../components/dr-alert';
import Button from '../../components/dr-button';
import DrCardMember from '../../components/dr-card-member';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import DrIconFont from '../../components/dr-icon-font';
import DrLoading from '../../components/dr-loading';
import DrModal from '../../components/dr-modal';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';
import { valueParam } from '../../utils/bibli';
import { ButtonDelete } from './styled';

const FamilyGroup: React.FC = () => {
  const { user, requestAPI, applicationData, sendError } = useAuth();

  const history = useHistory();

  const { addToast } = useToast();

  const [members, setMembers] = useState<ArrayMemberGroup>([]);
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = useState({ showModal: false, idTmGrupoFml: 0 });

  async function handleGetMembersData() {
    try {
      const result = await requestAPI({
        method: 'GET',
        url: `/tmUsuarioGrupofamiliarUsu?idpessoa_fisica=${user.idpessoa_fisica}`,
      });
      if (result.length) setMembers(result);
    } catch (err: any) {
      sendError(err);
      if (history.location.pathname !== '/dashboard') {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    handleGetMembersData();
  }, []);

  function mapArrayPatients({ patient }) {
    return (
      <Row
        className="justify-content-center mt-3"
        key={patient.idpessoa_fisica}
      >
        <Col md={8} lg={6} xl={5}>
          <ButtonDelete
            onClick={() =>
              setModal({
                showModal: true,
                idTmGrupoFml: patient.idtm_usuario_grupo_familiar,
              })
            }
          >
            <DrIconFont name="close" fontSize="20px" color="#777" />
          </ButtonDelete>

          <DrCardMember
            member={patient}
            urlImg={patient.url_img || valueParam(applicationData, 126)}
            onClick={() =>
              history.push(
                `/update-family-member/${patient.idtm_usuario_grupo_familiar}`
              )
            }
          />
        </Col>
      </Row>
    );
  }

  async function setInactive(id: number) {
    try {
      setLoading(true);
      await requestAPI({
        method: 'PUT',
        url: `tmUsuarioGrupoFamiliarInactive/${id}`,
      });

      setModal({ showModal: false, idTmGrupoFml: 0 });
      const result = members.filter(
        ({ idtm_usuario_grupo_familiar }) => idtm_usuario_grupo_familiar !== id
      );

      setMembers([...result] || []);
      setLoading(false);
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  return (
    <>
      <Header />
      <Container className="pb-3 pt-3">
        <Row className="col-xl-12 justify-content-center row-btn m-0 p-0">
          <Col xl={4} className="p-0">
            <Button
              spaceBetween
              type="button"
              color="blue"
              title="Adicionar dependente"
              onClick={() => history.push('/create-family-member')}
            />
          </Col>
          <Col xl={4} className="p-0">
            <Button
              spaceBetween
              type="button"
              title="Voltar"
              color="dark-grey"
              onClick={() => history.push('/profile')}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <CardBlank className="p-3" definedSize={loading ? '50vh' : undefined}>
          {loading ? (
            <DrLoading customLoading />
          ) : (
            <>
              <DrModal
                controllerModal={[modal.showModal, setModal]}
                title="Confirmação"
                buttons={{
                  confirm: {
                    title: 'Confirmar',
                    onClick: () => setInactive(modal.idTmGrupoFml),
                  },
                  cancel: {
                    title: 'Voltar',
                    onClick: () =>
                      setModal({ showModal: false, idTmGrupoFml: 0 }),
                  },
                }}
                content={
                  <h4 style={{ textAlign: 'center' }}>
                    Deseja realmente cancelar este dependente?
                  </h4>
                }
              />
              <PageTitle>Dependentes</PageTitle>

              {members.length ? (
                members.map((patient) =>
                  mapArrayPatients({
                    patient,
                  })
                )
              ) : (
                <Col className="m-0">
                  <DrAlert
                    warning
                    title={{
                      tag: 'h4',
                      text: '',
                    }}
                  >
                    <p>Você não possui membros familiares cadastrados</p>
                  </DrAlert>
                </Col>
              )}
            </>
          )}
        </CardBlank>
      </Container>

      <Footer />
    </>
  );
};
export default FamilyGroup;
