// @ts-nocheck
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';

import { CardGeral } from '../../styles/card-geral';
import { RowAnswer } from './styled';

import { useAuth } from '../../hooks/auth';
import { Links } from '../../pages/DetailsTopics/styled';
import { PageSubtitle } from '../../styles/page-title';
import DrCheckbox from '../dr-input-checkbox';

import { useToast } from '../../hooks/toast';

import DrIconFont from '../dr-icon-font';

interface DrCardForumProps extends React.HTMLAttributes<HTMLDivElement> {
  resposta: any;
  topico: any;
  resolved: any;
  handleAnswer: (data: any) => void;
  setTotalLikes: React.Dispatch<React.SetStateAction<any>>;
  index: number;
  handleResolved: (data: any) => void;
  handleShowModalLogin: () => void;
  handleCloseTopic: ({
    funcExec,
    showModal,
    idtopic,
    idforum_resposta,
    index,
  }: any) => void;
  HandleRenderImage: (person: any, image: string) => JSX.Element;
  handleRemove: (data: any) => void;
  formRef: any;
  handleSubmit: any;
}

const DrCardForum: React.FC<DrCardForumProps> = ({
  resposta,
  topico,
  resolved,
  index,
  handleResolved,
  setTotalLikes,
  HandleRenderImage,
  handleRemove,
  handleAnswer,
  handleShowModalLogin,
  handleCloseTopic,
  formRef,
  handleSubmit,
}: DrCardForumProps) => {
  const date = moment(resposta.dt_insert).fromNow(true);
  const { requestAPI, sendError, user } = useAuth();
  const [like, setLike] = useState({
    liked: !!resposta.idforum_curtida,
    qtde_curtida: resposta.qtde_curtida,
  });
  const likeRef = useRef(!!resposta.idforum_curtida);
  const { addToast } = useToast();

  React.useEffect(() => {
    if (resolved !== resposta.idforum) {
      formRef.current?.clearField(`ie_resolvido${index}`);
    }
  }, [resolved]);

  const handleBackGroundColor = () => {
    if (resposta.idpessoa_fisica === user?.idpessoa_fisica) return '#fff3cd36';
    if (resposta.idprofissional) return '#adf1d1';
    if (resposta.idusuario_resposta) return '#FCF4FF';
    return 'white';
  };

  const handleLike = useCallback(
    async (data) => {
      try {
        if (!user) {
          handleShowModalLogin();
          return;
        }
        setLike({
          qtde_curtida: likeRef.current
            ? (like.qtde_curtida += 1)
            : (like.qtde_curtida -= 1),
          liked: likeRef.current,
        });
        setTotalLikes((prev) => {
          // eslint-disable-next-line no-unused-expressions
          likeRef.current ? (prev += 1) : (prev -= 1);
          return prev;
        });
        await requestAPI({
          method: 'POST',
          url: '/forumCurtir',
          body: { ie_curtir: likeRef.current ? 'S' : 'N', idforum: data },
        });
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
        setLike({
          qtde_curtida: likeRef.current
            ? (like.qtde_curtida += 1)
            : (like.qtde_curtida -= 1),
          liked: likeRef.current,
        });
        setTotalLikes((prev) => {
          // eslint-disable-next-line no-unused-expressions
          likeRef.current ? (prev += 1) : (prev -= 1);
          return prev;
        });
      }
    },
    [like, likeRef.current]
  );
  const handleChangeCheckbox = React.useCallback(
    (ev: any) => {
      handleCloseTopic({
        execFunc: handleResolved,
        idforum: resposta.idforum,
        idforum_resposta: index > 0 ? resposta.idforum : undefined,
        ie_resolvido: ev.currentTarget.checked ? 'S' : 'N',
        chkEv: ev,
        index,
      });
    },
    [handleResolved]
  );

  return (
    <CardGeral
      className="mb-3 pt-1 pb-1"
      style={{
        backgroundColor: handleBackGroundColor(),
      }}
    >
      <p style={{ textAlign: 'right' }}>{date}</p>

      <HandleRenderImage person={resposta} image={resposta.url_img} />

      <PageSubtitle>{resposta.ds_mensagem}</PageSubtitle>

      <Row className="mt-2 d-flex" style={{ justifyContent: 'space-between' }}>
        <RowAnswer className="ml-1">
          <DrIconFont
            name="undo2"
            color="#0056b3"
            fontSize="30px"
            cursor="pointer"
            onClick={() =>
              handleCloseTopic({
                execFunc: handleAnswer,
                idforum: topico.idforum,
                idforum_resposta: index > 0 ? resposta.idforum : undefined,
              })
            }
          />
          <Links
            onClick={() =>
              handleCloseTopic({
                execFunc: handleAnswer,
                idforum: topico.idforum,
                idforum_resposta: index > 0 ? resposta.idforum : undefined,
              })
            }
            className="responder"
          >
            Responder
          </Links>
        </RowAnswer>

        {(topico.idpessoa_fisica === user?.idpessoa_fisica ||
          resposta.ie_resolvido === 'S') &&
          index > 0 && (
            <DrCheckbox
              noMargin
              name={`ie_resolvido${index}`}
              label={topico.ds_btn_resolvido}
              initialValue={resolved === resposta.idforum ? 'S' : 'N'}
              disabled={topico.idpessoa_fisica !== user?.idpessoa_fisica}
              onChange={handleChangeCheckbox}
              checked={resolved === resposta.idforum}
              value={resolved === resposta.idforum ? 'S' : 'N'}
            />
          )}

        {resposta.idpessoa_fisica === user?.idpessoa_fisica && (
          <DrIconFont
            onClick={() =>
              handleCloseTopic({
                execFunc: handleRemove,
                idforum: resposta.idforum,
                index,
                isDelete: true,
              })
            }
            name="trash-o"
            color="black"
            fontSize="30px"
            className="trash"
            cursor="pointer"
          />
        )}

        <Row className="align-items-baseline mr-1">
          <DrIconFont
            name={like.liked ? 'heart' : 'heart-o'}
            color={like.liked ? '#ef3170' : 'black'}
            fontSize="30px"
            onClick={() => {
              likeRef.current = !likeRef.current;
              handleLike(resposta.idforum);
            }}
            cursor="pointer"
          />
          <p className="m-0">{like.qtde_curtida}</p>
        </Row>
      </Row>
    </CardGeral>
  );
};

export default DrCardForum;
