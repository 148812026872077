// @ts-nocheck
import { useField } from '@unform/core';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { FieldIcon } from '../../styles/field-icon';
import DrIconFont from '../dr-icon-font';
import { Error, Field, FormLabel } from './styled';

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: JSX.Element | string;
  maxLength?: number;
  placeholder?: string;
  enabled?: boolean;
  onChange?: any;
}
const DrInputTextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  maxLength,
  onChange,
  placeholder,
  enabled = true,
  ...rest
}: TextAreaProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, registerField, error, clearError } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  function handleOnChange(ev) {
    clearError();
    if (onChange) {
      onChange(ev);
    }
  }
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Field isError={!!error} disabled={!enabled}>
        <textarea
          className="form-control"
          ref={inputRef}
          name={name}
          placeholder={placeholder}
          disabled={!enabled}
          defaultValue={rest.value}
          onChange={handleOnChange}
          maxLength={maxLength}
          data-cy={name}
        />
        {error && (
          <i>
            <FieldIcon>
              <Error
                visible={!!error}
                click={() => {
                  inputRef.current?.focus();
                }}
                title={error}
              >
                <DrIconFont name="alert" color="#c00" cursor="pointer" />
              </Error>
            </FieldIcon>
          </i>
        )}
      </Field>
    </>
  );
};

export default DrInputTextArea;
