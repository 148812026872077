// @ts-nocheck
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { FiHelpCircle } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import Button from '../../../components/dr-button';
import DrButtonLoading from '../../../components/dr-button-loading';
import HelmetComponent from '../../../components/dr-helmet-component';
import Input from '../../../components/dr-input';
import DrLoginSocial from '../../../components/dr-login-social';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { BoxLoginBody } from '../../../styles/box-login_body';
import { scrollPositionTop, verifyErrorYup } from '../../../utils/bibli';
import { DrBoxSignInContainer, ToolTipButton } from './styled';

interface ISignInFormData {
  login: string;
  password: string;
}

const DrBoxSignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [existeConta, setExisteConta] = useState(false);
  const { signIn, setDomainsData, user, sendError, requestAPI } = useAuth();
  const { addToast } = useToast();

  const history = useHistory();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const verificarSeExisteConta = async (data: any) => {
    if (data) {
      const res = await requestAPI({
        method: 'PUT',
        url: '/tmUsuarioExiste',
        body: { login: data },
        isPublic: true,
      });

      if (res.status) {
        setLoading(false);
        setExisteConta(!existeConta);
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    scrollPositionTop();
    if (user) {
      history.push('/dashboard');
      return;
    }

    async function getDomains() {
      try {
        setLoading(true);
        await setDomainsData();
      } catch (err: any) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      } finally {
        setLoading(false);
      }
    }
    getDomains();
  }, []);

  const closeDropdown = useCallback((e: any) => {
    if (
      dropdownRef.current !== null &&
      !dropdownRef.current.contains(e.target) &&
      e.target.nodeName !== 'path'
    ) {
      setVisible(false);
      document.removeEventListener('click', closeDropdown, false);
    }
  }, []);

  const openDropdown = useCallback(() => {
    setVisible((prev) => !prev);
    document.addEventListener('click', closeDropdown, false);
  }, []);

  const handleSubmit = async (data: ISignInFormData) => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});
      let schema;
      if (!existeConta) {
        schema = Yup.object().shape({
          login: Yup.string()
            .required('Campo obrigatório')
            .trim()
            .test({
              message:
                'Não foi possivel encontrar conta no Dr+. Crie sua Conta!',
              test: async (value) =>
                value ? verificarSeExisteConta(value) : true,
            }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        return;
      }

      schema = Yup.object().shape({
        login: Yup.string().required('Campo obrigatório').trim(),
        password: Yup.string()
          .matches(
            /^(((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
            'Senha deve conter no mínimo 6 caracteres contendo letras minúsculas, maiúsculas e números.'
          )
          .trim()
          .required('Campo obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await signIn({
        login: data.login,
        password: data.password,
      });
      setLoading(false);
    } catch (err: any) {
      sendError(err);
      setLoading(false);

      if (err.message.includes("'email'")) {
        history.push({
          pathname: `/resend-email`,
          state: JSON.parse(err.message.replace(/'/g, '"')),
        });
        return;
      }
      if (err instanceof Yup.ValidationError) {
        verifyErrorYup(err, formRef, addToast);
      } else {
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  };

  return (
    <>
      <HelmetComponent title="Login" />
      <DrBoxSignInContainer ref={dropdownRef}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Container>
            <Row>
              <Col xl={12}>
                <div className="login-tooltip">
                  <Col xl={11} className="p-0">
                    <Input
                      icon
                      name="login"
                      customType="login"
                      label="E-mail, Telefone ou CPF"
                      handleEnterKey={false}
                    />
                  </Col>
                  <div className="buttonTooltip">
                    <ToolTipButton
                      visible={visible}
                      title="Para acessar o Dr+ no campo login você pode informar: O seu e-mail ou telefone informado no cadastro com DDD ou se preferir pode informar o seu CPF"
                      click={() => openDropdown()}
                    >
                      <FiHelpCircle
                        color="rgb(119, 119, 119)"
                        size={15}
                        style={{}}
                        className="error-circle"
                        onClick={openDropdown}
                      />
                    </ToolTipButton>
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                {existeConta && (
                  <Input
                    icon
                    name="password"
                    label="Senha"
                    customType="password"
                    validate
                    handleEnterKey={false}
                  />
                )}
              </Col>
            </Row>
            <DrButtonLoading
              type="submit"
              title={`${existeConta ? 'Entrar' : 'Avançar'}`}
              color="pink"
              loading={loading}
            />
          </Container>

          {/* <ButtonLogin type="submit">Entrar</ButtonLogin> */}
        </Form>

        <Container className="pb-1">
          <BoxLoginBody
            className="mt-3 text-center"
            color="#ed145b"
            colorHover="#3f56a5"
          >
            <div className="mb-2">
              <Link className="recuperarSenha " to="forgot-password">
                Esqueci minha senha
              </Link>
            </div>
            <p className="pb-2">ou acesse com algumas de suas redes</p>
            <DrLoginSocial
              showFacebook
              showGoogle
              showApple
              loading={setLoading}
            />
            <p className="mb-2">Não tem uma conta?</p>
            <Button
              type="button"
              href="sign-up"
              title="Criar conta"
              color="dark-grey"
            />
          </BoxLoginBody>
        </Container>
      </DrBoxSignInContainer>
    </>
  );
};

export default DrBoxSignIn;
