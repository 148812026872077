// @ts-nocheck
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import { CardBlank } from '../../styles/card-blank';
import { PageTitle } from '../../styles/page-title';

import DrAlert from '../../components/dr-alert';
import DrBoxFicha from '../../components/dr-box-ficha';
import Button from '../../components/dr-button';
import DrButtonLoading from '../../components/dr-button-loading';
import DrCardLoading from '../../components/dr-card-loading';
import DrModal from '../../components/dr-modal';
import Plan from '../../components/dr-plan';
import { AttendancePlan, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { CardGeral } from '../../styles/card-geral';
import { CardGeralBody } from '../../styles/card-geral_body';
import { getJustFirstName } from '../../utils/bibli';
import { formatValue } from '../../utils/formatNumbers';
import { Ul } from '../Protocols/styled';
import {
  onWheel,
  scrollVisibilityApiType,
} from '../ScheduleAttendance/dr-card-attendance-schedule';
import { Arrow } from '../ScheduleAttendance/dr-card-attendance-schedule/styled';
import { BPLi, P, Strong } from './styled';

const LeftArrowPlan = () => {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Arrow
      hidden={isFirstItemVisible}
      aria-hidden="true"
      className="carousel-control-prev-icon"
      onClick={() => scrollPrev()}
    />
  );
};

const RightArrowPlan = () => {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <Arrow
      hidden={isLastItemVisible}
      aria-hidden="true"
      className="carousel-control-next-icon"
      onClick={() => scrollNext()}
    />
  );
};

const Plans: React.FC = () => {
  const { user, requestAPI, updateUser, sendError, applicationData } =
    useAuth();
  const { addToast } = useToast();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    showModal: false,
    planId: '0',
    msg: 'Deseja realmente cancelar este contrato?',
    err: false,
  });
  const [plans, setPlans] = useState([]);

  const [itemsPlan, setItemsPlan] = React.useState<any>([]);
  const [position, setPosition] = React.useState(100);
  const linkRef = React.useRef('');

  React.useEffect(() => {
    if (
      applicationData &&
      applicationData.valores_variaveis &&
      applicationData.valores_variaveis?.link_whatsapp &&
      applicationData.valores_variaveis?.link_whatsapp !== '-1'
    ) {
      const textWithName =
        applicationData.valores_variaveis?.link_whatsapp.replace(
          '{nm_pessoa}',
          getJustFirstName(user)
        );

      const textWithWeb = textWithName.replace('{plataforma}', 'Web');

      linkRef.current = `${textWithWeb} Gostaria de cancelar minha assinatura.`;
    }
  }, [applicationData, user]);

  async function setInactive(id: string) {
    setLoading(true);
    requestAPI({
      method: 'PUT',
      body: { idcontrato: id },
      url: '/tmContratoCancelar',
    })
      .then((resp) => {
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: resp.assinaturas_pln.msg,
        });
        setPlans((prev) =>
          prev.filter((item: any, idx) => item.idcontrato !== id)
        );
        const userUp = user;
        userUp.ds_produto = null;
        updateUser({ ...userUp });
        getPlansData();
      })
      .catch((err: any) => {
        sendError(err);

        if (err.data && err.data.msg_cancelamento)
          setModal({
            ...modal,
            showModal: true,
            msg: err.data.msg_cancelamento,
            err: true,
          });
        else
          addToast({
            type: 'error',
            title: 'Ops!',
            description: err.message,
          });
      })
      .finally(() => {
        modal.showModal = false;
        setLoading(false);
      });
  }
  async function getPlans() {
    requestAPI({ method: 'GET', url: '/tmAssinaturasPlano' })
      .then((resp) => {
        if (resp && resp.length) {
          const it: any = [];
          resp.forEach((contrato, idx) => {
            it.push({
              ...contrato,
              id: `element-${idx}`,
              ds_tipo: contrato.ds_tipo,
            });
          });
          setItemsPlan(it);
        }
      })
      .catch((err) => {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      });
  }

  async function getPlansData() {
    requestAPI({
      method: 'GET',
      url: `/tmUsuarioAssinatura?idpessoa_fisica=${user.idpessoa_fisica}`,
    })
      .then((resp) => {
        setPlans(resp);
        if (!resp.length) getPlans();
      })
      .catch((err: any) => {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
        history.push('/dashboard');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    getPlansData();
  }, []);

  const savePositionPlan = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    []
  );

  const restorePositionPlan = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        scrollContainer.current.scrollLeft = position;
      }
    },
    [position]
  );

  const handleSubmit = () => {
    if (modal.err) {
      const win = window.open(`${linkRef.current}`, '_blank');
      setModal({ ...modal, showModal: false });
      win?.focus();
    } else {
      setInactive(modal.planId);
      setModal({ ...modal, showModal: false });
    }
  };
  return (
    <>
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <>
          <Container className="mb-3 mt-3">
            <Row className="justify-content-center row-btn">
              <Col xl={4}>
                <Button
                  type="button"
                  color="dark-grey"
                  href="/profile"
                  title="Voltar"
                />
              </Col>
            </Row>
          </Container>
          <Container>
            <CardBlank className="pt-3">
              <Container style={{ padding: 0 }}>
                <DrModal
                  controllerModal={[modal.showModal, setModal]}
                  title="Confirmar cancelamento"
                  buttons={{
                    confirm: {
                      title: modal.err ? 'Falar com atendente' : 'Confirmar',
                      onClick: () => handleSubmit(),
                    },
                    cancel: {
                      title: 'Cancelar',
                      onClick: () => setModal({ ...modal, showModal: false }),
                    },
                  }}
                  content={<h4>{modal.msg}</h4>}
                />
                <>
                  {plans.length > 0 ? (
                    <PageTitle>
                      {plans.length > 1
                        ? 'Meus Contratos Assinados'
                        : 'Meu Contrato Assinado'}
                    </PageTitle>
                  ) : (
                    <Row className="col-xl-12 justify-content-center pt-1 m-0">
                      <Col>
                        <DrAlert
                          warning
                          title={{
                            tag: 'h4',
                            text: '',
                          }}
                        >
                          <p>Você não possui nenhum contrato assinado.</p>
                        </DrAlert>
                      </Col>
                    </Row>
                  )}
                </>
                {plans.length > 0 ? (
                  <>
                    {plans.map((plano: AttendancePlan, index: number) => (
                      <Row className="justify-content-center">
                        <Col md={12} xl={8} className="mb-3">
                          <CardGeral style={{ borderColor: '#ed145b' }}>
                            <CardGeralBody>
                              <Ul>
                                <BPLi
                                  style={{
                                    color: '#ed145b',
                                    borderBottom: '2px solid #7777771a',
                                    fontSize: 20,
                                    textAlign: 'center',
                                  }}
                                >
                                  {plano.produto_ser.ds_produto}
                                </BPLi>
                                {plano.produto_ser.itens.map((item) => (
                                  <BPLi
                                    style={{
                                      alignItems: 'flex-start',
                                      marginBottom: 5,
                                    }}
                                  >
                                    {item}
                                  </BPLi>
                                ))}

                                <BPLi color="#3f56a5" className="mt-3 ">
                                  {plano?.dependentes &&
                                    plano?.dependentes.length && (
                                      <div
                                        className="d-flex"
                                        style={{
                                          flexDirection: 'column',
                                          marginBottom: 10,
                                        }}
                                      >
                                        <Strong>
                                          {plano?.dependentes[0].ds_tipo}:
                                        </Strong>

                                        <P>{plano?.dependentes[0].nome}</P>
                                      </div>
                                    )}
                                </BPLi>
                                <BPLi color="#3f56a5" className="mt-3 ">
                                  {plano?.dependentes &&
                                    plano.dependentes.length > 1 && (
                                      <>
                                        <Strong>Dependentes:</Strong>
                                        {plano?.dependentes
                                          ?.slice(1)
                                          .map((dep, idx) => (
                                            <div
                                              className="d-flex"
                                              style={{
                                                flexDirection: 'column',
                                                alignItems: 'flex-start',
                                              }}
                                            >
                                              <P>
                                                {dep.nome} -{' '}
                                                {dep.ds_grau_parantesco}
                                              </P>
                                            </div>
                                          ))}
                                      </>
                                    )}
                                </BPLi>
                                <BPLi color="#3f56a5" className="mt-3">
                                  {plano?.contrato_regra_pagto?.ds_forma}{' '}
                                  {formatValue(
                                    plano?.contrato_regra_pagto?.vl_pagto as any
                                  )}
                                </BPLi>
                              </Ul>
                              <DrBoxFicha
                                type="P"
                                email={user.ds_email}
                                arrayDocs={[plans[index]]}
                                idAttendance={plano.idcontrato}
                              />
                              <DrButtonLoading
                                loading={loading}
                                type="button"
                                title="Cancelar"
                                color="pink"
                                onClick={() =>
                                  setModal({
                                    ...modal,
                                    showModal: true,
                                    planId: plano.idcontrato,
                                  })
                                }
                              />
                            </CardGeralBody>
                          </CardGeral>
                        </Col>
                      </Row>
                    ))}
                  </>
                ) : (
                  <>
                    {itemsPlan && itemsPlan.length > 0 && (
                      <CardBlank className=" pt-3 pb-3">
                        <PageTitle>Valores</PageTitle>

                        <ScrollMenu
                          itemClassName="row m-0"
                          LeftArrow={LeftArrowPlan}
                          RightArrow={RightArrowPlan}
                          onWheel={onWheel}
                          onInit={restorePositionPlan}
                          onScroll={savePositionPlan}
                          options={{
                            ratio: 0.9,
                            rootMargin: '5px',
                            threshold: 1,
                          }}
                        >
                          {itemsPlan.map((plano, idx) => (
                            <Plan
                              dashboard
                              title={plano.ds_tipo}
                              itemId={plano.id}
                              key={`#id_${idx.toString()}`}
                              plan={plano}
                              loading={false}
                              borderColor="#ed145b"
                            />
                          ))}
                        </ScrollMenu>
                      </CardBlank>
                    )}
                  </>
                )}
              </Container>
            </CardBlank>
          </Container>
        </>
      )}
      <Footer />
    </>
  );
};

export default Plans;
