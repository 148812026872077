// @ts-nocheck
import React from 'react';
import {
  BoxLogin,
  BoxLoginBack,
  BoxLoginIcon,
  BoxLoginTitle,
  BoxLoginTitleH2,
  BoxLoginTitleH3,
} from '../../styles/box-login';
import { CardBlank } from '../../styles/card-blank';
import DrIcon from '../dr-icon-font';
import { Container } from './styled';

interface DrBoxProps {
  className?: string | undefined;
  title?: string | undefined;
  subTitle?: string | undefined;
  titleColor?: string | undefined;
  arrowColor?: string | undefined;
  subTitleColor?: string | undefined;
  iconColor?: string | undefined;
  arrowOnClick?:
    | ((event: React.SyntheticEvent<HTMLAnchorElement>) => void)
    | false;
  icon?: React.ReactNode;
  header?: React.ReactNode;
  body?: React.ReactNode;
  footer?: React.ReactNode;
}
const DrBox: React.FC<DrBoxProps> = (
  props: React.PropsWithChildren<DrBoxProps>
) => {
  const {
    className,
    title,
    subTitle,
    subTitleColor,
    titleColor,
    arrowColor,
    arrowOnClick,
    icon,
    body,
    header,
    footer,
    children,
  } = props;

  return (
    <CardBlank>
      <BoxLogin>
        <Container className={className}>
          {arrowOnClick && (
            <BoxLoginBack onClick={arrowOnClick}>
              <DrIcon name="arrow-left" color={`${arrowColor}`} />
            </BoxLoginBack>
          )}
          <BoxLoginIcon>{icon}</BoxLoginIcon>
          {title && (
            <BoxLoginTitle>
              <BoxLoginTitleH2 color={`${titleColor}`}>{title}</BoxLoginTitleH2>
              <BoxLoginTitleH3 color={`${subTitleColor}`}>
                {subTitle}
              </BoxLoginTitleH3>
            </BoxLoginTitle>
          )}
          {header}
          {body}
          {footer}
          {children}
        </Container>
      </BoxLogin>
    </CardBlank>
  );
};
DrBox.defaultProps = {
  className: undefined,
  title: undefined,
  subTitle: undefined,
  titleColor: undefined,
  subTitleColor: undefined,
  iconColor: undefined,
  icon: false,
  header: false,
  body: false,
  footer: false,
  arrowColor: undefined,
  arrowOnClick: false,
};
export default DrBox;
