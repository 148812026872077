import axios from 'axios';
import { getBrowserInfo, getOrSetUserID } from '../utils/util';

const api = (header?: any) => {
  const { browserName, browserVersion } = getBrowserInfo();
  const uniqueid = getOrSetUserID();
  const headers = {
    'Content-Type': 'application/json',
    version: process.env.REACT_APP_VERSION,
    buildnumber: process.env.REACT_APP_BUILD,
    idaplicacao: process.env.REACT_APP_IDAPLICACAO,
    systemname: browserName || 'web',
    systemversion: browserVersion,
    deviceid: uniqueid,
    uniqueid,
    ...header,
  };

  return axios.create({
    baseURL: process.env.REACT_APP_API_ADDRESS,
    headers,
  });
};

export default api;
