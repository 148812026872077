// @ts-nocheck
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import moment from 'moment';
import React, { useCallback, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import DrButtonLoading from '../../components/dr-button-loading';
import DrCardLoading from '../../components/dr-card-loading';
import Footer from '../../components/dr-footer';
import Header from '../../components/dr-header';
import HelmetComponent from '../../components/dr-helmet-component';
import Input from '../../components/dr-input';
import Checkbox from '../../components/dr-input-checkbox';
import DrSelect from '../../components/dr-input-select';
import DrInputTextArea from '../../components/dr-input-text-area';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { CardBlank } from '../../styles/card-blank';
import { PageSubtitle, PageTitle } from '../../styles/page-title';
import {
  scrollPositionTop,
  valueParam,
  verifyErrorYup,
} from '../../utils/bibli';

import {
  CardConfig,
  CardConfigTitle,
} from '../../components/dr-card-config/styled';

import DrModal from '../../components/dr-modal';
import { ForumP } from './styled';

// import { Container } from './styles';

const defaultArray = [{ label: 'Selecione', value: '' }];
const Forum: React.FC = () => {
  const { applicationData } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [topics, setTopics] = useState([] as any);

  const [specialtiesTopics, setSpecialtiesTopics] = useState(defaultArray);
  const [categoryDomain, setCategoryDomain] = useState<any>(defaultArray);
  const [aboutDomain, setAboutDomain] = useState<any>(defaultArray);
  const [modalLike, setModalLike] = useState(false);
  const dataRef = React.useRef<any>(
    (() => {
      const data = localStorage.getItem('@ForumData');
      if (data) return JSON.parse(data);
      return {};
    })()
  );

  const history = useHistory();

  const { requestAPI, sendError, user, getDomainsData, domainsResp } =
    useAuth();
  const { addToast } = useToast();

  async function getForum() {
    setLoading(true);
    try {
      const resp = await requestAPI({ method: 'GET', url: '/forumPrincipal' });
      if (resp && resp.length) {
        const it: any = [];
        resp.forEach((forum) => {
          it.push({
            ...forum,
          });
        });
        setTopics(it);
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
      history.push('/dashboard');
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(
    () => () => {
      if (Object.keys(dataRef.current).length) {
        localStorage.setItem('@ForumData', JSON.stringify(dataRef.current));
      }
    },
    []
  );

  async function getForumEspecialidade() {
    try {
      const resp = await requestAPI({
        method: 'GET',
        url: '/forumEspecialidades',
      });
      if (resp) {
        const arrayFiltered = resp.map((array) => ({
          label: array.ds_especialidade,
          value: array.idespecialidade,
        }));
        arrayFiltered.unshift({ label: 'Selecione', value: '' });
        setSpecialtiesTopics(arrayFiltered);
      }
    } catch (err: any) {
      sendError(err);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  async function getData() {
    try {
      if (valueParam(applicationData, 215) === 'S')
        await getForumEspecialidade();

      if (valueParam(applicationData, 216) === 'S') {
        const category = getDomainsData('622').map((dominio) => ({
          label: dominio.ds_dominio_valor,
          value: dominio.vl_dominio,
        }));

        category.unshift({ label: 'Selecione', value: '' });
        setCategoryDomain(category);
      }
      if (valueParam(applicationData, 217) === 'S') {
        const about = getDomainsData('623').map((dominio) => ({
          label: dominio.ds_dominio_valor,
          value: dominio.vl_dominio,
        }));

        about.unshift({ label: 'Selecione', value: '' });
        setAboutDomain(about);
      }
    } catch (err: any) {
      history.push('/');
      addToast({
        type: 'error',
        title: 'Ops!',
        description: err.message,
      });
    }
  }

  React.useEffect(() => {
    scrollPositionTop();

    getData();
  }, [applicationData?.valores_parametros, domainsResp]);

  React.useEffect(() => {
    getForum();
  }, []);

  const handleSubmit = useCallback(async (data) => {
    try {
      if (!user) {
        setModalLike(true);
        return;
      }

      const schema = Yup.object().shape({
        ds_titulo: Yup.string()
          .required('Título é obrigatório. Por favor, informe o título!')
          .trim(),
        ...(valueParam(applicationData, 217) === 'S' && {
          ie_tipo_duvida: Yup.string().required('Campo obrigatório').trim(),
        }),
        ...(valueParam(applicationData, 216) === 'S' && {
          ie_categoria: Yup.string().required('Campo obrigatório').trim(),
        }),
        ...(valueParam(applicationData, 215) === 'S' && {
          idespecialidade: Yup.string().required('Campo obrigatório').trim(),
        }),
        ds_mensagem: Yup.string()
          .required('Dúvida é obrigatório. Por favor, descreva sua dúvida!')
          .trim(),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      if (!data.idespecialidade) data.idespecialidade = null;

      setIsLoading(true);

      const resp = await requestAPI({
        method: 'POST',
        url: '/forum',
        body: data,
      });

      addToast({
        type: 'success',
        title: 'Novo tópico criado com sucesso!',
        description: 'O seu novo tópico foi criado!',
      });
      setIsLoading(false);
      localStorage.removeItem('@ForumData');
      dataRef.current = {};
      history.push({
        pathname: `/details-topics/${resp.idforum}`,
        state: resp,
      });
    } catch (err: any) {
      setIsLoading(false);
      verifyErrorYup(err, formRef);
      if (!(err instanceof Yup.ValidationError)) {
        sendError(err);
        addToast({
          type: 'error',
          title: 'Ops!',
          description: err.message,
        });
      }
    }
  }, []);

  const renderInputCheckbox = React.useCallback(
    (param) => {
      switch (param) {
        case '1':
          return <Col md={12} xl={6} />;
        case '2':
          return (
            <Col md={12} xl={6}>
              <Checkbox
                name="ie_topico_privado"
                label="Privado - Sua dúvida ficará visível somente para você e o profissional"
                value="S"
                initialValue="S"
                disabled
              />
            </Col>
          );
        case '3':
          return (
            <Col md={12} xl={6}>
              <Checkbox
                name="ie_topico_privado"
                label="Privado - Sua dúvida ficará visível somente para você e o profissional"
              />
            </Col>
          );

        default:
          return <></>;
      }
    },
    [applicationData?.valores_parametros]
  );

  return (
    <>
      <HelmetComponent title="Dúvidas" />
      <Header />
      {loading ? (
        <DrCardLoading />
      ) : (
        <Container>
          <DrModal
            controllerModal={[modalLike, setModalLike]}
            title="Atenção"
            buttons={{
              confirm: {
                title: 'Login/Cadastro',
                onClick: () => {
                  history.push('/sign-in');
                },
              },
              cancel: {
                title: 'Cancelar',
                onClick: () => {
                  setModalLike(false);
                },
              },
            }}
            content={<h4>Necessário realizar Login ou Cadastro!</h4>}
          />
          <PageTitle>Dúvida sobre sua saúde?</PageTitle>
          <PageSubtitle textCenter>Pergunte ao Dr+</PageSubtitle>
          <CardBlank className="mt-3 pt-3 pb-3 mb-0">
            <Row>
              <Col>
                <PageTitle>
                  Deixe sua dúvida para que o médico responda
                </PageTitle>
              </Col>
            </Row>

            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={JSON.parse(
                localStorage.getItem('@ForumData') || '{}'
              )}
            >
              <Row className="m-0">
                <Col md={12} xl={12}>
                  <Input
                    name="ds_titulo"
                    label="Título da dúvida"
                    customType="name"
                    placeholder="Informe o que se trata sua dúvida"
                    getValue={(text) => {
                      dataRef.current = {
                        ...dataRef.current,
                        ds_titulo: text,
                      };
                    }}
                    value={dataRef.current.ds_titulo || ''}
                  />
                </Col>
              </Row>
              <Row className="m-0">
                {valueParam(applicationData, 215) === 'S' && (
                  <Col md={12} xl={4}>
                    <DrSelect
                      name="idespecialidade"
                      options={specialtiesTopics}
                      selectTitle="Especialidade"
                      value={dataRef.current.idespecialidade || '0'}
                      onChange={(ev) => {
                        dataRef.current = {
                          ...dataRef.current,
                          idespecialidade: ev.target.value,
                        };
                      }}
                    />
                  </Col>
                )}
                {valueParam(applicationData, 216) === 'S' && (
                  <Col md={12} xl={4}>
                    <DrSelect
                      name="ie_categoria"
                      options={categoryDomain}
                      selectTitle="Categoria"
                      onChange={(ev) => {
                        dataRef.current = {
                          ...dataRef.current,
                          ie_categoria: ev.target.value,
                        };
                      }}
                      value={dataRef.current.ie_categoria || '0'}
                    />
                  </Col>
                )}
                {valueParam(applicationData, 217) === 'S' && (
                  <Col md={12} xl={4}>
                    <DrSelect
                      name="ie_tipo_duvida"
                      options={aboutDomain}
                      selectTitle="Sobre"
                      value={dataRef.current.ie_tipo_duvida || '0'}
                      onChange={(ev) => {
                        dataRef.current = {
                          ...dataRef.current,
                          ie_tipo_duvida: ev.target.value,
                        };
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row className="m-0">
                <Col md={12}>
                  <DrInputTextArea
                    name="ds_mensagem"
                    label="Detalhe aqui sua dúvida"
                    placeholder="Informe com detalhes sua dúvida"
                    onChange={(ev) => {
                      dataRef.current = {
                        ...dataRef.current,
                        ds_mensagem: ev.target.value,
                      };
                    }}
                    value={dataRef.current.ds_mensagem || ''}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="ml-1 pl-4">
                  <PageTitle style={{ textAlign: 'left' }}>
                    {valueParam(applicationData, 218)}
                  </PageTitle>
                </Col>
              </Row>
              {renderInputCheckbox(valueParam(applicationData, 208))}
              <Row className="p-2 justify-content-center">
                <Col xl={4}>
                  <DrButtonLoading
                    spaceBetween
                    type="submit"
                    color="blue"
                    title="Enviar"
                    loading={isLoading}
                  />
                </Col>
              </Row>
            </Form>
          </CardBlank>
          {topics.length &&
            topics.map((topico: any, index: number) => {
              if (!topico.registros.length) return <></>;
              const dsTipo = topico.tipo;
              return (
                <Col
                  className="pl-0 pr-0 pt-3 pb-3 "
                  key={`#id_${index.toString()}`}
                >
                  <CardConfig
                    onClick={() => history.push('/latest-topics')}
                    style={{ cursor: 'pointer' }}
                  >
                    <Row
                      style={{ justifyContent: 'space-between' }}
                      className="pl-2 pr-2"
                    >
                      <CardConfigTitle>{topico.titulo}</CardConfigTitle>
                      <CardConfigTitle>
                        {topico.ds_coluna ? topico.ds_coluna : 'Atividades'}
                      </CardConfigTitle>
                    </Row>

                    {topico.registros.map((items: any, index2: number) => {
                      if (dsTipo === 1) {
                        const date = moment(items.dt_insert).fromNow(true);
                        return (
                          <Row
                            key={`#id_${index2.toString()}`}
                            style={{
                              justifyContent: 'space-between',
                              borderBottom: '1px solid #777',
                              margin: 0,
                            }}
                          >
                            <ForumP
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '70rem',
                                fontWeight: 'bold',
                              }}
                            >
                              {items.ds_titulo}
                            </ForumP>

                            <ForumP
                              style={{
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                maxWidth: '20rem',
                              }}
                            >
                              {date}
                            </ForumP>
                          </Row>
                        );
                      }
                      const dsQtde = items.qtde;
                      return (
                        <Row
                          key={`#id_${index2.toString()}`}
                          style={{
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #777',
                            margin: 0,
                          }}
                        >
                          <ForumP
                            style={{
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: '70rem',
                              fontWeight: 'bold',
                            }}
                          >
                            {topico.titulo === 'Especialidades'
                              ? items.ds_especialidade
                              : items.ds_titulo}
                          </ForumP>

                          <ForumP>{dsQtde}</ForumP>
                        </Row>
                      );
                    })}
                  </CardConfig>
                </Col>
              );
            })}
        </Container>
      )}
      <Footer />
    </>
  );
};

export default Forum;
