// @ts-nocheck
import React from 'react';
import VideoConvidado from '../dr-video-convidado';
import VideoHost from '../dr-video-host';
import { SectionStrPg } from './styled';

interface VideoConferenciaProps {
  localStream?: any;
  remoteStreams: Map<any, any>;
  stateRemoteCameraOn: boolean;
  urlImgRemoto: any;
  urlImgUser: string;
  isLoadingVidHost: boolean;
  remoteVideoHtmlRef: React.RefObject<HTMLVideoElement>;
}

const VideoConferencia: React.FC<VideoConferenciaProps> = (
  props: VideoConferenciaProps
) => {
  const {
    localStream,
    remoteStreams,
    stateRemoteCameraOn,
    urlImgRemoto,
    urlImgUser,
    isLoadingVidHost,
    remoteVideoHtmlRef,
  } = props;

  return (
    <SectionStrPg className="d-flex">
      <VideoConvidado
        remoteStreams={remoteStreams}
        urlImgRemoto={urlImgRemoto}
        stateCameraOn={stateRemoteCameraOn}
        videoHtmlRef={remoteVideoHtmlRef}
      />

      <VideoHost
        localStream={localStream ? localStream.streamJVideo : null}
        id={localStream ? localStream.id : null}
        urlImg={urlImgUser}
        isLoading={isLoadingVidHost}
      />
    </SectionStrPg>
  );
};

VideoConferencia.defaultProps = {
  localStream: undefined,
};

export default VideoConferencia;
