// @ts-nocheck
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import DrBoxFicha from '../../../components/dr-box-ficha';
import Button from '../../../components/dr-button';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import DrCardLoading from '../../../components/dr-card-loading';
import EvaluationComponent from '../../../components/EvaluationComponent';
import { EvaluationFormData, useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { CardBlank } from '../../../styles/card-blank';
import { PageSubtitle, PageTitle } from '../../../styles/page-title';
import { scrollPositionTop } from '../../../utils/bibli';

import { SpanLabel } from './styled';

interface IdRoute {
  id: string;
}

interface AttendanceExames {
  url_relatorio: string;
  idrelatorio_gerado: string;
}
interface AttendanceReceitas {
  url_relatorio: string;
  idrelatorio_gerado: string;
}

interface AttendanceAtestados {
  url_relatorio: string;
  idrelatorio_gerado: string;
}
interface AttendanceFichasMedicas {
  idatendimento_paciente: number;
  url_relatorio: string;
  idrelatorio_gerado: string;
}

interface AttendanceReceipt {
  ds_situacao: string;
  dt_recebimento: string;
  idcaixa_recebimento: number;
  idconta_receber_cartao_movto: number;
  idrelatorio_gerado: number;
  ie_autorizado: string;
  ie_situacao: string;
  nr_autorizacao: string;
  nr_cartao: string;
  url_recibo: string;
  vl_transacao: string;
}
interface AttendanceTypes {
  idatendimento_paciente: number;
  idpessoa_fisica: number;
  idmedico_atendimento: number;
  idatend_original: number;
  nm_pessoa: string;
  nm_paciente: string;
  sg_conselho: string;
  nr_conselho: string;
  uf_conselho: string;
  idespecialidade: number;
  ds_classificacao: string;
  ds_especialidade: string;
  data_atend_medico: string;
  pedidos_exames: AttendanceExames[] | any;
  receitas: AttendanceReceitas[] | any;
  atestados: AttendanceAtestados[] | any;
  fichas_medicas: AttendanceFichasMedicas[] | any;
  idficha_avaliacao_gerada: number;
  dt_avaliacao: string;
  status: string;
  avaliacao_atendimento: string;
  nr_dias_restante_ret: number;
  idclassificacao: number;
  recibo_pg: AttendanceReceipt;
  anexosPaciente: [] | any;
  anexosMedico: [] | any;
  chatMensagens: [] | any;
}

function handleReturnAttendance({ historic, setAttendanceData, history }) {
  let string;

  if (historic.nr_dias_restante_ret === 0)
    string = 'Hoje é o último dia para realizar a consulta!';
  if (historic.nr_dias_restante_ret === 1)
    string = 'Amanhã será o último dia para o seu atendimento';

  let text = (
    <PageTitle className="page-title text-center" color="#777">
      {string}
    </PageTitle>
  );

  if (historic.nr_dias_restante_ret > 1)
    text = (
      <PageTitle className="page-title text-center" color="#777">
        {`Você tem `}
        <small
          style={{
            color: '#3f56a5',
            fontWeight: 700,
            fontSize: '0.875em',
          }}
        >
          {`${historic.nr_dias_restante_ret} dias`}
        </small>
        {` `}
        para realizar o retorno deste atendimento
      </PageTitle>
    );

  return (
    <>
      {historic.nr_dias_restante_ret > -1 ? (
        <CardBlank>
          <Container className="pt-3 pb-3 mt-4">
            {text}
            <Row className="justify-content-center">
              <Col xl={5}>
                <Button
                  type="button"
                  onClick={async () => {
                    await setAttendanceData(
                      {
                        ...historic,
                        ie_retorno: historic.ie_retorno
                          ? historic.ie_retorno
                          : 'S',
                        idpessoa_paciente: historic.idpessoa_fisica,
                        idpessoa_titular: historic.idpessoa_titular,
                        fromReturn: true,
                      },
                      true
                    );
                    if (historic.ie_tipo_atendimento === 3) {
                      history.push({ pathname: '/return-attendance' });
                    } else {
                      history.push({ pathname: '/select-specialty' });
                    }
                  }}
                  title={
                    historic.ie_tipo_atendimento === 3
                      ? 'Realizar Retorno'
                      : 'Marcar Retorno'
                  }
                  color="pink"
                />
              </Col>
            </Row>
          </Container>
        </CardBlank>
      ) : (
        <></>
      )}
    </>
  );
}

const DrAttendanceData: React.FC = () => {
  const { user, requestAPI, setAttendanceData, sendError } = useAuth();
  const [isLoading, setLoading] = useState(true);
  const [attendanceDatas, setAttendanceDatas] = useState<AttendanceTypes>();
  const [evaluation, setEvaluation] = useState<EvaluationFormData>();
  const history = useHistory();
  const { params } = useRouteMatch<IdRoute>();
  const { addToast } = useToast();
  const location = useLocation<IdRoute>();

  useEffect(
    () =>
      history.listen((locationx) => {
        if (history.action === 'POP' && locationx) {
          if (location.state?.id === 'fromAttendanceEnd') {
            history.push('/dashboard');
          }
        }
      }),
    []
  );

  useEffect(() => {
    scrollPositionTop();
    async function getData() {
      try {
        const resp = await requestAPI({
          method: 'GET',
          url: `dadosAtendimentoPacienteUser?idatendimento_paciente=${params.id}`,
        });

        if (resp.nr_dias_restante_ret >= 0) {
          resp.ie_retorno = 'S';
        }
        setAttendanceDatas(resp);
        const {
          idatendimento_paciente,
          idclassificacao,
          idespecialidade,
          idparametro,
          idpessoa_fisica,
          ie_classificacao_agenda,
          ie_telemedicina,
          ie_tipo_agenda,
          ie_tipo_atendimento,
          modalidade,
          ie_retorno,
          idconvenio,
          idpessoa_titular,
          idestabelecimento_tipo_atendimento,
          idestabelecimento,
          anexosPaciente,
          anexosMedico,
          chatMensagens,
        } = resp;
        await setAttendanceData(
          {
            idatendimento_paciente,
            idclassificacao,
            idespecialidade,
            idparametro,
            idpessoa_paciente: idpessoa_fisica,
            ie_classificacao_agenda,
            ie_telemedicina,
            ie_tipo_agenda,
            ie_tipo_atendimento,
            modalidade,
            ie_retorno,
            idconvenio,
            idpessoa_titular,
            idestabelecimento_tipo_atendimento,
            idestabelecimento,
            anexosPaciente,
            anexosMedico,
            chatMensagens,
          },
          true
        );
        if (Object.keys(resp.avaliacao_atendimento).length)
          setEvaluation(resp.avaliacao_atendimento);
      } catch (error: any) {
        sendError(error);
        history.push('/');
        addToast({
          type: 'error',
          title: 'Sem informações a ser exibido!',
          description: error.message.replace('Validation error: ', ''),
        });
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  return (
    <>
      {isLoading ? (
        <DrCardLoading className="mt-4 pt-4 pb-4" minHeight="35vh" />
      ) : (
        <>
          {attendanceDatas && params && (
            <CardBlank>
              <Container className="pt-3 pb-3">
                <PageTitle className="page-title text-center" color="#3f56a5">
                  Dados do Atendimento
                </PageTitle>
                <Row>
                  <Col xl={6}>
                    <PageSubtitle>
                      <SpanLabel>Data:</SpanLabel>{' '}
                      {attendanceDatas?.data_atend_medico}
                    </PageSubtitle>
                    {attendanceDatas.nm_paciente && (
                      <PageSubtitle>
                        <SpanLabel>Paciente:</SpanLabel>{' '}
                        {attendanceDatas?.nm_paciente}
                      </PageSubtitle>
                    )}
                    <PageSubtitle>
                      <SpanLabel>Tipo de Atendimento:</SpanLabel>{' '}
                      {attendanceDatas?.ds_classificacao}
                    </PageSubtitle>
                  </Col>
                  <Col xl={6}>
                    <PageSubtitle>
                      <SpanLabel>Dr(a):</SpanLabel> {attendanceDatas?.nm_pessoa}
                    </PageSubtitle>
                    <PageSubtitle>
                      <SpanLabel>Especialidade:</SpanLabel>{' '}
                      {attendanceDatas?.ds_especialidade} -{' '}
                      {attendanceDatas?.uf_conselho}
                    </PageSubtitle>
                    <PageSubtitle>
                      <SpanLabel>CRM:</SpanLabel> {attendanceDatas?.nr_conselho}
                    </PageSubtitle>
                  </Col>
                </Row>
                <DrBoxFicha
                  type="F"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.fichas_medicas}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="E"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.pedidos_exames}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="R"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.receitas}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="A"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.atestados}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="C"
                  email={user?.ds_email}
                  arrayDocs={[attendanceDatas?.recibo_pg]}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="AP"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.anexosPaciente}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="AM"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.anexosMedico}
                  idAttendance={params.id}
                />
                <DrBoxFicha
                  type="CHAT"
                  email={user?.ds_email}
                  arrayDocs={attendanceDatas?.chatMensagens}
                  idAttendance={params.id}
                />
              </Container>
            </CardBlank>
          )}

          {attendanceDatas?.nr_dias_restante_ret &&
            handleReturnAttendance({
              historic: attendanceDatas,
              setAttendanceData,
              history,
            })}

          <EvaluationComponent
            idatendimento_paciente={attendanceDatas?.idatendimento_paciente}
            evaluationDatas={evaluation}
          />

          <DrButtonsFooter
            buttonRight={{
              title: `${
                location.state?.id === 'fromAttendanceEnd'
                  ? 'Finalizar'
                  : 'Voltar'
              }`,
              onClick: () => {
                if (location.state?.id === 'fromAttendanceEnd') {
                  history.push('/dashboard');
                } else {
                  history.goBack();
                }
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default DrAttendanceData;
