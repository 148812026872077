// @ts-nocheck
import React, { useCallback, useRef, useState } from 'react';
import { Container } from './styled';

interface TooltipProps {
  title: string;
  className?: string;
  visible?: boolean;
  click?: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({
  title,
  className = '',
  children,
  visible = false,
  click,
}) => {
  const [visib, setVisible] = useState(visible);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleShowTooltip = useCallback(() => {
    if (click) {
      setVisible((prev) => !prev);
      click();
    }
  }, []);

  return (
    <Container
      className={className}
      isVisible={visib}
      onClick={handleShowTooltip}
    >
      {children}
      <span ref={dropdownRef}>{title}</span>
    </Container>
  );
};

export default Tooltip;
