// @ts-nocheck
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';
import { PageTitle } from '../../styles/page-title';
import DrCardLoading from '../dr-card-loading';
import DrShowSchedulesSpecialties from '../dr-show-schedules-specialties';
import {
  Container,
  ContainerShowSpecialities,
  HeaderContainer,
  Title,
  TitleContainer,
} from './styled';

interface DataProps {
  horarios: any;
  idespecialidade: number;
  ds_especialidade: string;
  ie_dependente: string;
  ie_tipo_atendimento: string;
  ie_categoria_atend: string;
  tipos_atendimento: number[];
}

const DrShowSpecialities = () => {
  const [specialities, setSpecialities] = useState<Array<DataProps>>();
  const [loading, setLoading] = useState(true);
  const { requestAPI, sendError, setAttendanceData } = useAuth();

  const history = useHistory();
  useEffect(() => {
    requestAPI({
      method: 'GET',
      url: '/agendaEspecialidades?detailHours=S&aplicaFiltroMedico=true&qtd_medico=3',
    })
      .then((response) => {
        setSpecialities(response);
      })
      .catch((error) => {
        sendError(error);
      })
      .finally(() => setLoading(false));
  }, []);

  async function handleClick(data: DataProps) {
    const {
      tipos_atendimento,
      idespecialidade,
      ie_categoria_atend,
      ie_dependente,
      ie_tipo_atendimento,
    } = data;

    const [idestabelecimento_tipo_atendimento] = tipos_atendimento;
    await setAttendanceData(
      {
        idestabelecimento_tipo_atendimento,
        tipos_atendimento,
        idespecialidade,
        ie_categoria_atend,
        ie_dependente,
        ie_tipo_atendimento,
        origem_cartao: true,
      },
      true
    );
    history.push('/types-care');
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loading ? (
        <DrCardLoading />
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {specialities && specialities.length > 0 && (
            <ContainerShowSpecialities>
              <PageTitle>Especialidades Disponíveis</PageTitle>

              {specialities &&
                specialities?.map((specialty) => (
                  <Container
                    onClick={() => {
                      handleClick(specialty);
                    }}
                  >
                    <HeaderContainer>
                      <TitleContainer>
                        <Title>{specialty.ds_especialidade}</Title>
                      </TitleContainer>
                    </HeaderContainer>

                    <DrShowSchedulesSpecialties
                      specialty={specialty}
                      styleGrid="row"
                      color="black"
                      screen="selectSpecialties"
                    />
                  </Container>
                ))}
            </ContainerShowSpecialities>
          )}
        </>
      )}
    </>
  );
};

export default DrShowSpecialities;
