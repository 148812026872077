// @ts-nocheck
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import DrAlert from '../../../components/dr-alert';
import Button from '../../../components/dr-button';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import DrCardMember from '../../../components/dr-card-member';
import { AuthContextData } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import { scrollPositionTop } from '../../../utils/bibli';
import { CheckboxDepedents } from './styled';

interface DependentProps {
  history: any;
  dependentes: any;
  qtdDependentes: number;
  attendance: AuthContextData['attendance'];
  setAttendanceData: AuthContextData['setAttendanceData'];
  sendError: (message: any) => Promise<void>;
}

function mapArrayPatients({
  idx,
  selectDependent,
  selected,
  selectedPatientRef,
  ...rest
}: any) {
  const objStyle: any = {
    ...(idx === selectedPatientRef ? { pointerEvents: 'none' } : ''),
  };

  return (
    <Container>
      <Row
        className={`justify-content-center${idx !== 0 ? ' mt-3' : ''}`}
        key={rest.patient.idpessoa_fisica}
      >
        <Col md={8} lg={6} xl={5} style={objStyle}>
          <DrCardMember
            member={rest.patient}
            urlImg={rest.patient.url_img}
            onClick={() =>
              selectDependent({
                idx,
              })
            }
          />
          <CheckboxDepedents
            type="checkbox"
            checked={selected[idx]}
            onChange={({ target }) => selectDependent({ idx })}
            disabled={idx === selectedPatientRef}
          />
        </Col>
      </Row>
    </Container>
  );
}

const DrCardDependents: React.FC<DependentProps> = (Props: DependentProps) => {
  const {
    history,
    dependentes,
    qtdDependentes,
    attendance,
    setAttendanceData,
    sendError,
  } = Props;

  const { addToast } = useToast();

  const [selected, setSelected] = React.useState(dependentes.map(() => false));
  const [count, setCount] = React.useState(0);

  const selectedPatient = React.useRef();

  const selectDependent = React.useCallback(
    ({ idx }) => {
      const selectionLength = selected.filter(
        (selection) => selection === true
      );
      setCount(selectionLength);
      if (selectionLength.length >= qtdDependentes) {
        setSelected((prev) => {
          const prevSelect = [...prev];
          prevSelect[idx] = false;
          return [...prevSelect];
        });
      } else if (selectionLength.length < qtdDependentes) {
        setSelected((prev) => {
          const prevSelect = [...prev];
          prevSelect[idx] = !prevSelect[idx];
          return [...prevSelect];
        });
      }
    },
    [qtdDependentes, setSelected, selected]
  );
  const handleSubmit = React.useCallback(async () => {
    const result: any = [];
    try {
      if (count < qtdDependentes)
        throw new Error('Quantidade de dependentes menor que o mínimo');

      selected.forEach((item, idx) => {
        if (item === true) {
          result.push({
            idpessoa_fisica: dependentes[idx].idpessoa_fisica,
            ie_grau_parentesco: dependentes[idx].ie_grau_parentesco,
          });
        }
      });
      attendance.assinaturas_pln.dependentes = result;
      await setAttendanceData({ assinaturas_pln: attendance.assinaturas_pln });

      history.push({
        pathname: `/payment`,
      });
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `${error.message}`,
      });
    }
  }, [
    addToast,
    count,
    dependentes,
    history,
    attendance.assinaturas_pln,
    qtdDependentes,
    selected,
    setAttendanceData,
  ]);

  React.useEffect(() => {
    const arr = selected.filter((select) => select === true);
    setCount(arr.length);
  }, [selected]);

  React.useEffect(() => {
    scrollPositionTop();
    setSelected((prev) => {
      if (attendance) {
        const idxDefaultSelected = dependentes.findIndex(
          (idx) => idx.idpessoa_fisica === attendance.idpessoa_paciente
        );
        prev[idxDefaultSelected] = true;
        selectedPatient.current = idxDefaultSelected;
        return [...prev];
      }
      return [...prev];
    });
  }, [dependentes, attendance]);

  return (
    <>
      <Container className="pt-3">
        <CardBlank className="pb-3" definedSize="50vh">
          <>
            <PageTitle className="pt-3">
              {`Selecione os beneficiados para seu contrato (0${
                count || `0`
              } de 0${qtdDependentes})`}
            </PageTitle>
            {dependentes.length > 0 &&
              dependentes.map((patient, idx) =>
                mapArrayPatients({
                  patient,
                  idx,
                  selectDependent,
                  selected,
                  selectedPatientRef: selectedPatient.current,
                })
              )}
          </>
          {!dependentes.length && (
            <Col className="justify-content-center mt-4">
              <DrAlert
                warning
                title={{
                  tag: 'h4',
                  text: '',
                }}
              >
                <p>Deve cadastrar o dependente que fará parte do contrato!</p>
              </DrAlert>
            </Col>
          )}
          {count > 0 && (
            <Row className="justify-content-center mt-4">
              <Col md={12} lg={12} xl={4} className="pl-4 pr-4">
                <Button
                  type="button"
                  color="pink"
                  title="Confirmar seleção"
                  onClick={() => handleSubmit()}
                />
              </Col>
            </Row>
          )}
        </CardBlank>
      </Container>

      <DrButtonsFooter
        buttonLeft={{
          title: `${
            attendance.ie_categoria_atend === 'P'
              ? 'Cadastrar criança'
              : 'Cadastrar paciente'
          }`,
          onClick: () =>
            history.push({
              pathname: `/create-family-member/`,
              state: 'select-dependents',
            }),
        }}
        buttonRight={{
          title: '  Voltar',
          onClick: () =>
            history.push(
              attendance.fromDashboard ? '/dashboard' : '/pay-options'
            ),
        }}
      />
    </>
  );
};

export default DrCardDependents;
