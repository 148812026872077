// @ts-nocheck
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../components/dr-button';
import DrCardMember from '../../../components/dr-card-member';
import DrLoading from '../../../components/dr-loading';
import DrModal from '../../../components/dr-modal';
import {
  AuthContextData,
  MemberType,
  useAuth,
  User,
} from '../../../hooks/auth';
import { ToastMessage, useToast } from '../../../hooks/toast';
import { CardBlank } from '../../../styles/card-blank';
import { PageTitle } from '../../../styles/page-title';
import {
  attendanceOpened,
  handleNextScreenAttendanceOpened,
} from '../../../utils/bibli';

import DrAlert from '../../../components/dr-alert';
import DrButtonsFooter from '../../../components/dr-buttons-footer';
import { useIsMounted } from '../../../hooks/is-mounted';
import { userRequirements } from '../../../utils/validations';
import { CheckboxDepedents } from '../../SelectDependents/dr-card-dependents/styled';

interface modalInt {
  showModal: boolean;
  title?: string;
}

interface getDataParams {
  user: User;
  patient: MemberType | User;
  respAttendanceRef: React.MutableRefObject<any>;
  setAttendanceData: AuthContextData['setAttendanceData'];
  history: any;
  addToast: (message: Omit<ToastMessage, 'id'>) => void;
  requestAPI: AuthContextData['requestAPI'];
  setModal: React.Dispatch<React.SetStateAction<modalInt>>;
  isMountedRef: React.MutableRefObject<boolean>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  attendance?: any;
}

interface mapArrayPatientsParams extends getDataParams {
  idx: number;
}

interface IRouteParams {
  singleType?: boolean;
}

const PatientsCard: React.FC = () => {
  const {
    requestAPI,
    user,
    setAttendanceData,
    attendance,
    sendError,
    applicationData,
  } = useAuth();

  const history = useHistory();
  const { addToast } = useToast();
  const location = useLocation<IRouteParams>();

  const isMountedRef = useIsMounted();

  const [modal, setModal] = React.useState<modalInt>({
    showModal: false,
  });

  const [isLoading, setLoading] = React.useState(true);

  const respAttendanceRef = React.useRef<any>();
  const [members, setMembers] = React.useState([]);
  const [selected, setSelected] = React.useState<any>([]);
  React.useEffect(() => {
    if (!user) history.push('/types-care');
  }, []);

  React.useEffect(() => {
    async function getMembersData() {
      requestAPI({
        method: 'GET',
        url: `/tmUsuarioGrupoFamiliarUsu?idpessoa_fisica=${
          user.idpessoa_fisica
        }${
          attendance.idestabelecimento_tipo_atendimento
            ? `&idestabelecimento_tipo_atendimento=${attendance.idestabelecimento_tipo_atendimento}`
            : ''
        }`,
      })
        .then((resp) => {
          setMembers(resp);
          setSelected(() => resp.map(() => false));
        })
        .catch((error: any) => {
          sendError(error);
          history.push('/dashboard');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    getMembersData();
  }, []);

  React.useEffect(() => {
    async function getData() {
      if (
        attendance &&
        attendance.ie_dependente &&
        attendance.ie_dependente !== 'S'
      ) {
        await attendanceOpened({
          user,
          patient: user,
          respAttendanceRef,
          setAttendanceData,
          setModal,
          setLoading,
          requestAPI,
          addToast,
          isMountedRef,
          history,
          userRequirements,
          fromReturn: false,
          attendance,
          applicationData,
        });
      }
    }
    getData();
  }, [history]);

  const selectDependent = React.useCallback(
    (idx) => {
      setSelected((prev) => {
        prev.forEach((check: any, index) => {
          if (idx === index) {
            prev[index] = true;
          } else {
            prev[index] = false;
          }
        });
        return [...prev];
      });
    },
    [members, setSelected, selected]
  );

  const handleSubmit = React.useCallback(async () => {
    const result: any = [];
    try {
      if (selected && selected.length) {
        selected.forEach((item, idx) => {
          if (item === true && members[idx]) {
            result.push({
              patient: members[idx],
            });
          }
        });

        if (result && result.length) {
          await attendanceOpened({
            patient: result[0]?.patient,
            respAttendanceRef,
            setAttendanceData,
            setModal,
            setLoading,
            requestAPI,
            addToast,
            isMountedRef,
            history,
            user,
            userRequirements,
            fromReturn: false,
            attendance,
            applicationData,
          });
          await setAttendanceData({
            patient: result[0]?.patient,
          });
        } else {
          throw new Error(
            'Deve selecionar o paciente que receberá atendimento ou cadastrar um novo!'
          );
        }
      } else {
        throw new Error(
          'Deve selecionar o paciente que receberá atendimento ou cadastrar um novo!'
        );
      }
    } catch (error: any) {
      sendError(error);
      addToast({
        type: 'error',
        title: 'Ops!',
        description: `${error.message}`,
      });
    }
  }, [
    addToast,
    members,
    history,
    attendance.assinaturas_pln,
    selected,
    setAttendanceData,
  ]);

  const onNavBack = () => {
    if (attendance && (attendance.origem_cartao || attendance.singleType)) {
      history.push('/dashboard');
    } else {
      history.push('/types-care');
    }
  };

  function mapArrayPatients({ idx, ...rest }: mapArrayPatientsParams) {
    return (
      <Container key={rest.patient.idpessoa_fisica}>
        <Row className={`justify-content-center${idx !== 0 ? ' mt-3' : ''}`}>
          <Col md={8} lg={6} xl={5}>
            <DrCardMember
              member={rest.patient}
              urlImg={rest.patient.url_img}
              onClick={() => selectDependent(idx)}
            />
            <CheckboxDepedents
              type="checkbox"
              checked={selected[idx] === true}
              onChange={({ target }) => selectDependent(idx)}
            />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <DrModal
        controllerModal={[modal.showModal, setModal]}
        title={modal.title || ''}
        buttons={{
          confirm: {
            title: 'Confirmar',
            onClick: () =>
              handleNextScreenAttendanceOpened({
                respAttendanceRef,
                history,
                ie_tipo_atendimento: attendance.ie_tipo_atendimento,
                isMountedRef,
                fromReturn: false,
              }),
          },
          cancel: {
            title: 'Voltar',
            onClick: () => setModal({ showModal: false }),
          },
        }}
      />
      <Container className="pt-3">
        <CardBlank
          className="pb-3"
          definedSize={isLoading ? '50vh' : undefined}
        >
          {isLoading ? (
            <DrLoading customLoading />
          ) : (
            <>
              <PageTitle>
                {` ${
                  attendance.ie_categoria_atend === 'P'
                    ? 'Selecione a criança'
                    : 'Selecione o paciente'
                }`}
              </PageTitle>
              {members.length ? (
                members.map((patient, idx) =>
                  mapArrayPatients({
                    user,
                    patient,
                    respAttendanceRef,
                    setAttendanceData,
                    history,
                    addToast,
                    requestAPI,
                    idx,
                    setModal,
                    isMountedRef,
                    setLoading,
                    attendance,
                  })
                )
              ) : (
                <Col className="m-0">
                  <DrAlert
                    warning
                    title={{
                      tag: 'h4',
                      text: '',
                    }}
                  >
                    <p>
                      Nenhuma pessoa encontrada para este tipo de atendimento,
                      por favor adicione um dependente
                    </p>
                  </DrAlert>
                </Col>
              )}
              <Row className="justify-content-center mt-4">
                <Col md={12} lg={12} xl={4} className="pl-4 pr-4">
                  <Button
                    type="button"
                    color="pink"
                    title="Confirmar seleção"
                    onClick={() => handleSubmit()}
                  />
                </Col>
              </Row>
            </>
          )}
        </CardBlank>
      </Container>

      <DrButtonsFooter
        buttonLeft={
          attendance.ie_dependente === 'S' && {
            title: `${
              attendance.ie_categoria_atend === 'P'
                ? 'Cadastrar nova criança'
                : 'Cadastrar novo paciente'
            }`,
            onClick: () => {
              history.push({
                pathname: `/create-family-member/`,
                state: 'new-attendance',
              });
            },
          }
        }
        buttonRight={{
          title: '  Voltar',
          onClick: async () => {
            onNavBack();
            await setAttendanceData({ singleType: undefined });
          },
        }}
      />
    </>
  );
};

export default PatientsCard;
