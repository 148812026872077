// @ts-nocheck
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CardBlank } from '../../styles/card-blank';
import { Fields } from '../../styles/fields';
import { PageTitle } from '../../styles/page-title';
import Button, { DrButtonLoadingProps } from '../dr-button-loading';
import { ModalFooter } from './styled';

export interface DrModalContentProps {
  title?: string | false;
  loading?: boolean;
  content?: React.ReactNode;
  customContent?: React.ReactNode;
  bodyHeight?: string;
  buttons?:
    | {
        confirm?: Partial<DrButtonLoadingProps>;
        changePatient?: Partial<DrButtonLoadingProps>;
        cancel?: Partial<DrButtonLoadingProps>;
        startConsultation?: Partial<DrButtonLoadingProps>;
      }
    | false;
}

const confirmDefault: DrButtonLoadingProps = {
  type: 'button',
  title: 'Confirmar',
  color: 'blue',
  loading: false,
};

const changePatientDefault: DrButtonLoadingProps = {
  type: 'button',
  title: 'Alterar paciente',
  color: '#ed145b',
  loading: false,
};
const startConsultation: DrButtonLoadingProps = {
  type: 'button',
  title: 'Iniciar Consulta',
  color: '#ed145b',
  loading: false,
};

const cancelDefault: DrButtonLoadingProps = {
  type: 'button',
  title: 'Cancelar',
  color: 'dark-grey',
  loading: false,
};

function createObjButton(
  objParam: Partial<DrButtonLoadingProps> | undefined,
  objDefault: DrButtonLoadingProps
) {
  if (!objParam) return false;
  const obj = { ...objDefault, ...objParam };

  return obj;
}

const DrModalContent: React.FC<DrModalContentProps> = ({
  title,
  content,
  buttons,
  customContent,
  bodyHeight,
}: DrModalContentProps) => {
  let btnConfirm: DrButtonLoadingProps | false = false;
  let btnChangePatient: DrButtonLoadingProps | false = false;
  let btnCancel: DrButtonLoadingProps | false = false;
  let btnStartConsultation: DrButtonLoadingProps | false = false;

  if (buttons) {
    btnConfirm = createObjButton(buttons.confirm, confirmDefault);
    btnChangePatient = createObjButton(
      buttons.changePatient,
      changePatientDefault
    );
    btnStartConsultation = createObjButton(
      buttons.startConsultation,
      startConsultation
    );
    btnCancel = createObjButton(buttons.cancel, cancelDefault);
  }

  React.useEffect(() => {
    btnConfirm = false;
    btnChangePatient = false;
    btnStartConsultation = false;
    btnCancel = false;
    return () => {
      btnConfirm = false;
      btnChangePatient = false;
      btnCancel = false;
      btnStartConsultation = false;
    };
  }, []);

  return (
    <CardBlank className="pt-3">
      <Container>
        {title && (
          <Col className="p-0">
            <PageTitle>{title}</PageTitle>
          </Col>
        )}
        {customContent || (
          <Col className="p-0">
            {content && (
              <Fields>
                <Row>
                  <Col xl={12}>{content}</Col>
                </Row>
              </Fields>
            )}
          </Col>
        )}
      </Container>

      {buttons && (
        <ModalFooter style={{ border: 0 }}>
          {btnConfirm && (
            <Col className="p-1">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Button {...btnConfirm} />
            </Col>
          )}

          {btnChangePatient && (
            <Col className="p-1">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Button {...btnChangePatient} />
            </Col>
          )}
          {btnStartConsultation && (
            <Col className="p-1">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Button {...btnStartConsultation} />
            </Col>
          )}

          {btnCancel && (
            <Col className="p-1">
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Button {...btnCancel} />
            </Col>
          )}
        </ModalFooter>
      )}
    </CardBlank>
  );
};

DrModalContent.defaultProps = {
  title: false,
  content: false,
  buttons: false,
  loading: false,
  customContent: undefined,
};

export default DrModalContent;
